import React, { Component } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

export default class CreateFormModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            label: "",
            description: "",
            image_header: '',
            image_footer: '',
            email_formdatas: false,
            email_workflows: false,
            email_comments: false,
            type: "C",
            timmer: 0,
            timer: false,
            lock_status:false,
            data_secure:true
        }
    }
    render() {
        return (
            <Modal show={this.props.createFormStatus} onHide={() => this.props.setCreateFormStatus(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ width: "100%" }}>
                        <Form.Group className="mb-2" controlId="formGridAddress1">
                            <Form.Label>Name</Form.Label>
                            <Form.Control placeholder="Enter Form Name"
                                onChange={(data) => this.setState({ label: data.target.value })} />
                        </Form.Group>

                        <Form.Group className="mb-2" controlId="formGridAddress2">
                            <Form.Label>Description</Form.Label>
                            <Form.Control type='text' as={'textarea'} placeholder="Enter Form Description" onChange={(data) => this.setState({ description: data.target.value })} />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Header Banner</Form.Label>
                            <Form.Control type="file" onChange={event => { this.setState({ image_header: event.target.files[0] }) }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Footer Banner</Form.Label>
                            <Form.Control type="file" onChange={event => { this.setState({ image_footer: event.target.files[0] }) }} />
                        </Form.Group>
<Row>
    <Col md={4}>
    {this.state.timer !== true ?
                            <Form.Group className="mb-2">
                                <Form.Label>Timer</Form.Label>
                                <Form.Check type="switch" label="Add Timer?" checked={this.state.timer}
                                    onChange={() => this.setState({ timer: !this.state.timer })} />
                            </Form.Group> :
                            <Form.Group className="mb-2">
                                <Form.Label>Timer</Form.Label>
                                <Form.Control size="sm" type="number" placeholder="Enter Time In Sec" required value={this.state.timmer}
                                    onChange={event => { this.setState({ timmer: event.target.value }); }} />
                            </Form.Group>

                        }
    </Col>
    <Col md={4}>
        
<Form.Group className="mb-2">
                                            <Form.Label>Auto Lock</Form.Label>
                                            <Form.Check type="switch" label="In Auto Lock?" checked={this.state.lock_status}
                                                onChange={() => this.setState({ lock_status: !this.state.lock_status })} />
                                      </Form.Group>
    </Col>
    <Col md={4}>
    <Form.Group className="mb-2">
                                            <Form.Label>Data Secure</Form.Label>
                                            <Form.Check type="switch" label="In Data Secure?" checked={this.state.data_secure}
                                                onChange={() => this.setState({ data_secure: !this.state.data_secure })} />
                                        </Form.Group>
    </Col>
</Row>
                       

                                  
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        this.props.setCreateFormStatus(false);
                        this.setState({
                            label: '',
                            description: '',
                            image_header: null,
                            image_footer: null,
                            timmer: 0,
                            timer: false,
                            lock_status:false,
            data_secure:true
                        })
                    }}>
                        Close
                    </Button>
                    <Button variant="success" onClick={() => {
                        this.props.onCreateForm(this.state);
                        this.setState({
                            label: '',
                            description: '',
                            image_header: null,
                            image_footer: null,
                            timmer: 0,
                            timer: false,
                            lock_status:false,
            data_secure:true
                        })
                    }}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
