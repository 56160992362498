import React, { Component } from "react";
import "./StyleSheets/Navbar.css";
import ProfileImageDropdown from "./ProfileImageDropdown";
import Toaster from "../views/common/components/Toaster";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { getUserProfile } from "../views/users/action";
import { Button } from "react-bootstrap";
import { redirect } from "../lib/utils";
import { Link } from "react-router-dom";
import GIGA from "../Images/final.png"


class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToggleBottom: false,
      showProfile: false,
    };
  }

  componentDidMount() {
    this.props.getUserProfile(this.props.token.access);
  }

  render() {
    
    return (
      <div className="header-section" id="sticky">
        <nav className="navbar navbar-expand-lg navbar-light primary">
          <div className="container-fluid ">
            <div className="d-inline-flex">
              {
                localStorage.getItem('key') === "admin" || localStorage.getItem('teamReport') ?
                  <div onClick={() => this.props.onToggleClick()}>
                    <i className="fa fa-bars large" aria-hidden="true" style={{ marginTop: "7px" }}></i>
                  </div> :
                  <div></div>
              }
              <a href="/dashboard"><img src={GIGA} style={{ width: "100px", marginLeft: "20px" }} /></a>
            </div>
            <div className="d-inline-flex">
              <div>
               {this.props.profileData?.is_superuser===true &&  <Button className="mr-3" onClick={() => {
                  localStorage.getItem("key") === 'user' ? localStorage.setItem('key', 'admin') : localStorage.setItem('key', 'user');
                  window.location.reload();
                  redirect('/dashboard')
                }} style={{ marginRight: 15 }} variant="light">{localStorage.getItem('key') === 'user' ? "ADMIN CONSOLE" : "USER CONSOLE"}</Button> } 
              
              </div>
              <div>
                <ProfileImageDropdown />
              </div>
            </div>


            {/* <button
              className="btn btn-dark d-inline-block d-lg-none ml-auto"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fa fa-align-justify"></i>
            </button> */}

            {/* <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            > */}
            {/* <ul className="nav navbar-nav ml-auto">
                <li className="nav-item active">
                  <a className="nav-link" href="/about">
                    Page
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/index">
                    Page
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/page">
                    Page
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/component">
                    Page
                  </a>
                </li>
              </ul> */}
            {/* </div> */}
          </div>
        </nav>
        <Row style={{ position: "absolute", right: 0 }} >
          <Col>
            <Toaster />
          </Col>
        </Row>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    token: state.usersReducer.token,
    profileData: state.usersReducer.profileData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserProfile: (token) => dispatch(getUserProfile(token))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
