import React, { useEffect, useState, useCallback } from 'react';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createCustomDashboard, getCustomDashboardById } from './action';
import { setNotification } from '../common/action';
import { fetchFields, fetchForms, fetchFormsList } from '../forms/action';
import { fetchWorkflowStateList } from '../workflows/action';
import { getCustomDashboard } from '../users/action';
import DataTable from '../common/components/DataTable';
import CustomDashboardUpdateModal from './CustomDashboardUpdateModal';
import { BUTTONS, dashboardIcon } from '../../lib/constants';

const CustomDashboard = (props) => {
    const [dashboardName, setDashboardName] = useState("");
    const [tabledata, setTableData] = useState([]);
    const [tabledatashow, setTableDatashow] = useState([]);
    const [localWorkflowStateList, setLocalWorkflowStateList] = useState([]);
    const [allfield, setallfield] = useState([]);
    const [selectedData, setselectedData] = useState([])

    const [state, setState] = useState({
        name: '',
        type: '',
        template: '',
        field: '',
        function: '',
        states: '',
        dashboardname: {},
        showUpdateModal: false,
        buttonType: '',
        indivisual: '',
        icon: ''
    })
    const handleChange = (e) => {

        const { name, value } = e.target
        setState((prevstate) => ({
            ...prevstate,
            [name]: value
        }))
    }

    useEffect(() => {
        props.getFormList(props.token?.access);

    }, [props.token?.access]);

    // const fieldHandle = useCallback((formId) => {

    //     props.getFields(props.token?.access, formId, 1);
    //     props.getForm(props.token?.access, formId);

    // }, [props.token?.access]);

    const fieldHandle = useCallback(async (formId, callback) => {
        try {
          // Fetch fields for the given formId
          const fieldsResponse = await props.getFields(props.token?.access, formId, 1);
          
          // Execute callback with the response data
          if (callback) {
            callback(fieldsResponse?.data || []);
          }
        } catch (error) {
          console.error("Error fetching fields:", error);
      
          // Execute callback with an empty array in case of error
          if (callback) {
            callback([]);
          }
        }
        
        // Optionally fetch the form
        try {
          await props.getForm(props.token?.access, formId);
        } catch (error) {
          console.error("Error fetching form:", error);
        }
      }, [props.token?.access, props.getFields, props.getForm]);
      
    useEffect(() => {
        if (Array.isArray(props.fields) && props.fields.length > 0) {
            setallfield(props.fields); // Update allfield whenever props.fields changes
        } else {
            setState((prevState) => ({
                ...prevState,
                field: '', // Set default field to the first item’s id
            }));
            setallfield([])
        }
    }, [props.fields]);
    useEffect(() => {
        if (Array.isArray(allfield) && allfield.length > 0) {
            setState((prevState) => ({
                ...prevState,
                field: allfield[0]?.id, // Set default field to the first item’s id
            }));
        }
    }, [allfield]);

    useEffect(() => {
        if (props.form?.workflow) {
            props.fetchWorkflowStateList(props.token?.access, props.form.workflow);
        }
        else {
            setState((prevstate) => ({
                ...prevstate,
                states: ""
            }))
            setLocalWorkflowStateList([])
        }

    }, [props.form, props.token?.access]);
    useEffect(() => {
        if (props.workflowStateList?.results) {
            setLocalWorkflowStateList(props.workflowStateList.results);
        }
    }, [props.workflowStateList]);
    useEffect(() => {
        // Set the initial default value for states if data is available in localWorkflowStateList
        if (localWorkflowStateList.length > 0) {
            setState((prevState) => ({
                ...prevState,
                states: localWorkflowStateList[0].id // Set default to first item's id
            }));
        }
    }, [localWorkflowStateList]);

    const addData = () => {
        const newData = [state.name, state.type, state.template, state.field, state.function, state.states, state.buttonType, state.icon, state.indivisual];
        console.log(newData, 'newData')
        if (state.name) {
            setTableData((prevTableData) => [...prevTableData, newData]);



            const selectedField = props.fields.find(field => field.id === state.field);
            const templateName = props.formList?.data?.find(template => template.id === Number(state.template))?.label || '';
            const stateName = localWorkflowStateList.find(item => item.id === Number(state.states))?.label || '';
            // Prepare data for the table (store the name, type, template, field name, function, state)
            const newDatashow = [
                state.name,
                state.type,
                templateName,
                selectedField ? selectedField.label : '',  // Store the field name (label) in the table
                state.function,
                stateName,
                state.buttonType, state.icon, state.indivisual,

            ];
            setTableDatashow((prevTableData) => [...prevTableData, newDatashow]);
            setState({
                name: "",
                type: '',
                template: '',
                field: '',
                function: '',
                states: '',
                buttonType: '',
                indivisual: '',
                icon: ''
            });
            setallfield([]);
            setLocalWorkflowStateList([])
        } else {
            props.setNotification(true, "Please fill all fields.", "danger");
        }
    }
    const handleCreateAction = (e) => {
        e.preventDefault();
        if (dashboardName && tabledata.length > 0) {
            const requestBody = {
                dashboard: {
                    dashboard_name: dashboardName
                },
                details: tabledata.map((data) => ({
                    name: data[0],
                    type: data[1],
                    template: Number(data[2]),
                    field: Number(data[3]),
                    function: data[4],
                    state: Number(data[5]),
                    colour:data[6],
                    icon:data[7],
                    individual:data[8],
                }))
            };
            props.createCustomDashboard(props.token?.access, requestBody);
        } else {
            props.setNotification(true, "Please Add Data in Table.", "danger");
        }
    };
    useEffect(() => {
        if (props.token?.access) {
            props.getCustomDashboard(props.token.access, (response) => {
                setState({ dashboardname: response || [] }); // Set the dashboard data correctly
            });
        }
    }, [props.token?.access])
    const handleEditDashboard = (id) => {
        setState((prev) => ({
            ...prev,
            showUpdateModal: true,

        }));
        props.getCustomDashboardById(props.token?.access, id, (res) => {

            setselectedData(res)
        })
    }
    return (
        <div className="main-content-container p-4 container-fluid">
            <Row>
                <Col>
                    <p>Custom Dashboard</p>
                    <Card>
                        <Card.Body className="font-medium">
                            <Form>
                                <Row>
                                    <Col md={4} className="p-4">
                                        <Form.Label>
                                            Dashboard Name <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            placeholder="Enter Dashboard Name"
                                            required
                                            value={dashboardName}
                                            onChange={(e) => setDashboardName(e.target.value)}
                                        />
                                    </Col>
                                    <Col md={12} className="px-4">
                                        <Table striped bordered hover size="sm">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Type</th>
                                                    <th>Template</th>
                                                    <th>Field</th>
                                                    <th>Function</th>
                                                    <th>State</th>
                                                    <th>Color</th>
                                                    <th>Icon</th>
                                                    <th>Individual</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Form.Control
                                                            size="sm"
                                                            type="text"
                                                            placeholder="Enter Name"
                                                            required
                                                            name="name" // Matches the state key
                                                            value={state.name} // Bound to the state
                                                            onChange={handleChange} // Updates the state on change
                                                        />
                                                    </td>
                                                    <td>
                                                        <Form.Select
                                                            name="type"
                                                            value={state.type}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="Card">Card</option>
                                                            <option value="Table">Table</option>
                                                        </Form.Select>
                                                    </td>
                                                    <td>
                                                        <Form.Select
                                                            name="template"
                                                            value={state.template}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                fieldHandle(e.target.value);
                                                            }}
                                                        >
                                                            <option value="">Select</option>
                                                            {Array.isArray(props.formList?.data) && props.formList?.data.length > 0 ? (
                                                                props.formList?.data.map((template, index) => (
                                                                    <option key={index} value={template.id}>{template.label}</option>
                                                                ))
                                                            ) : (
                                                                <option disabled>No templates available</option>
                                                            )}
                                                        </Form.Select>
                                                    </td>
                                                    <td>
                                                        <Form.Select
                                                            name="field"
                                                            value={state.field}
                                                            onChange={handleChange}
                                                        >

                                                            {Array.isArray(allfield) && allfield.length > 0 ? (
                                                                allfield.map((field, index) => (

                                                                    <option key={index} value={field.id}>{field.label}</option>
                                                                ))
                                                            ) : (
                                                                <option disabled>No Field available</option>
                                                            )}
                                                        </Form.Select>
                                                    </td>
                                                    <td>
                                                        <Form.Select
                                                            name="function"
                                                            value={state.type === "Table" ? "" : state.function}
                                                            onChange={handleChange}
                                                            disabled={state.type === "Table"}
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="Count">Count</option>
                                                            <option value="Max">Max</option>
                                                            <option value="Average">Average</option>
                                                            <option value="Total">Total</option>
                                                        </Form.Select>
                                                    </td>
                                                    <td>
                                                        <Form.Select
                                                            name="states"
                                                            value={state.states}
                                                            onChange={handleChange}
                                                        >
                                                            {localWorkflowStateList.length > 0 ? (
                                                                localWorkflowStateList.map((item, index) => (
                                                                    <option key={index} value={item.id}>{item.label}</option>
                                                                ))
                                                            ) : (
                                                                <option disabled>No State available</option>
                                                            )}
                                                        </Form.Select>
                                                    </td>
                                                    <td>
                                                        <Form.Select aria-label="Default select example" required size="sm" value={state.buttonType}
                                                            name='buttonType'
                                                            onChange={handleChange}>
                                                            <option value="">Select</option>
                                                            {BUTTONS.map((ele, index) => {
                                                                return (
                                                                    <option value={ele.value} key={index}>{ele.label}</option>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                    </td>
                                                    <td>
                                                        <Form.Select
                                                            aria-label="Default select example"
                                                            required
                                                            size="sm"
                                                            name='icon'
                                                            value={state.icon}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">Select</option>
                                                            {dashboardIcon.map((ele, index) => (
                                                                <option value={ele.value} key={index}>
                                                                    {ele.icon}
                                                                </option>
                                                            ))}
                                                        </Form.Select>

                                                    </td>
                                                    <td>
                                                        <Form.Select
                                                            name="indivisual"
                                                            value={state.indivisual}
                                                            onChange={handleChange}
                                                            required

                                                        >
                                                            <option value="">Select</option>
                                                            <option value="true">True</option>
                                                            <option value="false">False</option>

                                                        </Form.Select>
                                                    </td>
                                                    <td>
                                                        <Button
                                                            variant="outline-success"
                                                            size="sm"
                                                            onClick={addData}
                                                        >
                                                            Add
                                                        </Button>
                                                    </td>
                                                </tr>
                                                {tabledatashow.map((data, index) => (
                                                    <tr key={index}>
                                                        <td>{data[0]}</td>
                                                        <td>{data[1]}</td>
                                                        <td>{data[2]}</td>
                                                        <td>{data[3]}</td>
                                                        <td>{data[4]}</td>
                                                        <td>{data[5]}</td>
                                                        <td>{data[6]}</td>
                                                        <td>{data[7]}</td>
                                                        <td>{data[8]}</td>
                                                        <td></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Col md={12} className="d-flex justify-content-end p-4">
                                        <Button
                                            variant="outline-success"
                                            size="sm"
                                            onClick={handleCreateAction}
                                        >
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>

                            <div className="mt-3 font-medium">
                                {state.dashboardname?.results && state.dashboardname?.results?.length > 0 && (
                                    <DataTable
                                        totalItemsCount={state.dashboardname?.count}
                                        onPageChange={(pageNumber) =>
                                            props.getCustomDashboard(
                                                props.token?.access, () => { },
                                                pageNumber,

                                            )
                                        }
                                        headers={['Dashboard Name', 'Action']}
                                        body={state.dashboardname?.results?.map((row, index) => {
                                            return [
                                                row.dashboard_name,
                                                <Row key={index}>
                                                    <Col mr="6">
                                                        <Button
                                                            type='button'
                                                            title="Edit"
                                                            className="m-1"
                                                            variant="info"
                                                            size="sm"
                                                            onClick={() => {
                                                                handleEditDashboard(row.id)
                                                            }}
                                                        >
                                                            <i
                                                                className="fa fa-pencil"
                                                                aria-hidden="true"
                                                                style={{
                                                                    fontSize: '15px',
                                                                    color: 'white',
                                                                    padding: '2px 3px',
                                                                }}
                                                            ></i>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            ];
                                        })}
                                    />
                                )}
                            </div>
                            <CustomDashboardUpdateModal
                                fieldHandle={fieldHandle}
                                fields={props.fields}
                                forms={props.form}
                                formLists={props.formList}
                                localWorkflowStateList={localWorkflowStateList}
                                showModal={state.showUpdateModal}
                                handleClose={() => setState((prev) => ({ ...prev, showUpdateModal: false }))}
                                dashboardData={selectedData}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state) => ({
    token: state.usersReducer.token,
    formList: state.formsReducer.formList,
    fields: state.formsReducer.fields,

    form: state.formsReducer.form,
    workflowStateList: state.workflowReducer.workflowStateList,
});

const mapDispatchToProps = (dispatch) => ({
    createCustomDashboard: (accessToken, requestBody) => dispatch(createCustomDashboard(accessToken, requestBody)),
    setNotification: (show, text, color) => dispatch(setNotification(show, text, color)),
    getFormList: (accessToken) => dispatch(fetchFormsList(accessToken)),
    getFields: (accessToken, formId, pageNumber) => dispatch(fetchFields(accessToken, formId, pageNumber)),
    getForm: (accessToken, templateId) => dispatch(fetchForms(accessToken, templateId)),
    fetchWorkflowStateList: (accessToken, workflowId) => dispatch(fetchWorkflowStateList(accessToken, workflowId)),
    getCustomDashboard: (accessToken, fun) =>
        dispatch(getCustomDashboard(accessToken, fun)),
    getCustomDashboardById: (accessToken, id, fun) => dispatch(getCustomDashboardById(accessToken, id, fun))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomDashboard);
