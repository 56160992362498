import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form, Table } from 'react-bootstrap';

import Select from 'react-select';
import { createAssignWorkflow, deleteAssignWorkflowDetails, fetchAllAssignWorkflowDetails, fetchAllGroups } from '../../groupManagement/action';
import { configureWorkflow, fetchWorkflowStateList, fetchconfigureWorkflow } from '../../workflows/action';
import { setNotification } from '../../common/action';
import { fetchFields, fetchForms, fetchFormsList } from '../../forms/action';
import DeleteModal from '../../common/components/DeleteModal';

const AssignWorkflowModal = (props) => {
  const { showModal, handleClose, token, formList, form, workflowStateList, groupId } = props;

  const [templates, setTemplates] = useState("");
  const [state, setState] = useState([]);
  const [localWorkflowStateList, setLocalWorkflowStateList] = useState([]);
  const [selectedData, setselectedData] = useState(null);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [apidata, setApidata] = useState([])
  console.log(selectedData)
  useEffect(() => {
    if (token?.access) {
      props.getFormList(token.access);
    }
  }, [token?.access]);

  const templateChange = (e) => {
    const selectedTemplate = e.target.value;
    setTemplates(selectedTemplate);
    if (selectedTemplate) {
      props.getFields(token.access, selectedTemplate);
    }
  };

  useEffect(() => {
    if (form?.workflow) {
      props.fetchWorkflowStateList(token.access, form.workflow);
    } else {
      setState([]);
      setLocalWorkflowStateList([]);
    }
  }, [form, token?.access]);

  useEffect(() => {
    if (workflowStateList?.results) {
      setLocalWorkflowStateList(workflowStateList.results);
    }
  }, [workflowStateList]);

  const handleStateChange = (selectedOptions) => {
    setState(selectedOptions || []); // Ensure empty array if no options selected
  };

  const templateOptions = localWorkflowStateList?.map((item) => ({
    value: item.id,
    label: item.label,
  }));
  useEffect(() => {
    if (groupId && props.token?.access) {


      props.fetchAllAssignWorkflowDetails(props.token.access, groupId, (response) => {
        setApidata(response)
      });
    }
  }, [props.token?.access, groupId]);

  const refreshTableData = () => {
    if (groupId && props.token?.access) {
        props.fetchAllAssignWorkflowDetails(props.token.access, groupId, (response) => {
            setApidata(response);
        });
    }
};

  const SubmitHandle = (e) => {
    e.preventDefault();
    if (templates && state.length > 0) {


      const requestBody = {
        template_id: templates,
        group_id: groupId,
        state_ids: state.map(data => ({ id: data.value }))
      }
      props.createAssignWorkflow(props.token?.access, requestBody,()=>{
        refreshTableData(); 
      })
      
      setTemplates("")
      setState([])

      // handleClose();
    } else {
      props.setNotification(true, "Field is Required", "danger");
    }
  }
  const deleteAction = async (template_id) => {
    try {
      // Perform delete operation and wait for it to complete
      await props.deleteAssignWorkflowDetails(
        props.token.access,
        groupId,
        template_id.template_id,
        () => { 
          refreshTableData(); 
        }
      );

    

      // Reset selected data
      setselectedData(null);

      // Optionally close the modal
      // handleClose();
    } catch (error) {
      console.error("Error during delete and fetch:", error);
      // Optionally handle error (e.g., show a notification)
    }
  };


  console.log(state, 'state')
  return (
    <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>Assign Workflow</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Template Dropdown */}
        <Form.Select
          placeholder="Select a Template"
          aria-label="Default select example"
          style={{
            width: '100%',
            padding: '0.375rem 0.75rem',
            border: '1px solid #ced4da',
            color: '#495057',
            marginBottom: '15px',
          }}
          value={templates}
          onChange={templateChange}
        >
          <option value="">Select Template</option>
          {formList?.data?.map((item) => (
            <option key={item.id} value={item.id}>
              {item.label}
            </option>
          ))}
        </Form.Select>

        {/* State Multi-Select */}
        <Select
          placeholder="Select State"
          onChange={handleStateChange}
          value={state} // Bind state to selected options
          isMulti
          name="template"
          options={templateOptions}
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} variant="warning" size="sm">
          CANCEL
        </Button>
        <Button variant="success" size="sm" onClick={SubmitHandle}>
          ADD
        </Button>
      </Modal.Footer>
      <div style={{ padding: "10px 15px" }}>
        <Table striped bordered hover size="sm" >
          <thead>
            <tr>

              <th>Template</th>
              <th>State</th>
              <th>Action</th>

            </tr>
          </thead>
          <tbody>
            {apidata?.results?.map((item, index) =>
              <tr key={index}>
                <td>{item.template_name}</td>
                <td>{item.states}</td>
                <td>
                  <Button
                    title="Delete"
                    className="m-1"
                    variant="danger"
                    size="sm"
                    onClick={() => {
                      setshowDeleteModal(true);
                      setselectedData(item); // Set selected data correctly
                    }}
                  >
                    <i
                      className="fa fa-trash-o"
                      aria-hidden="true"
                      style={{
                        fontSize: '15px',
                        color: 'white',
                        padding: '2px 3px',
                      }}
                    ></i>
                  </Button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      <DeleteModal
        modalText="Delete ?"
        showModal={showDeleteModal}
        handleClose={() => setshowDeleteModal(false)}
        deleteAction={(status) => {
          if (status !== undefined && status === true) {
            deleteAction(selectedData); // Pass selectedData to delete
          }
        }}
      />
    </Modal>
  );
};


const mapStateToProps = (state) => ({
  token: state.usersReducer.token,
  formList: state.formsReducer.formList,
  workflowStateList: state.workflowReducer.workflowStateList,
  form: state.formsReducer.form,
});

const mapDispatchToProps = (dispatch) => ({
  getFormList: (accessToken, searchQuery, pageNumber) => dispatch(fetchFormsList(accessToken, searchQuery, pageNumber)),
  fetchconfigureWorkflow: (accessToken, template_id, state_id, fun) => dispatch(fetchconfigureWorkflow(accessToken, template_id, state_id, fun)),
  // configureWorkflow: (accessToken, template_id, state_id, requestBody) => dispatch(configureWorkflow(accessToken, template_id, state_id, requestBody)),
  setNotification: (show, text, color) => dispatch(setNotification(show, text, color)),
  fetchWorkflowStateList: (accessToken, workflowId) => dispatch(fetchWorkflowStateList(accessToken, workflowId)),
  getFields: (accessToken, formId) => dispatch(fetchForms(accessToken, formId)),
  createAssignWorkflow: (accessToken, requestBody,fun) => dispatch(createAssignWorkflow(accessToken, requestBody,fun)),
  fetchAllAssignWorkflowDetails: (accessToken, id, fun) => dispatch(fetchAllAssignWorkflowDetails(accessToken, id, fun)),
  deleteAssignWorkflowDetails: (accessToken, groupid, tempid, fun) => dispatch(deleteAssignWorkflowDetails(accessToken, groupid, tempid, fun))
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignWorkflowModal);
