import React, { Component } from 'react';
import { Form, Col } from 'react-bootstrap';
import Dropdown from './Dropdown';
import RadioButton from './RadioButton';
import Checkbox from './Checkbox';
import FileUpload from './FileUpload';
import { validator } from '../../../../lib/Validation';
import { FIELD_VALUE_MAPPING } from '../../../../lib/constants';
import { fetchFieldValue } from '../../action';
import { connect } from 'react-redux';
import Table from '../../../customforms/customfields/Table';



class FieldHandler extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hiddenfieldValue: '',
        }
    }

    componentDidMount() {
        if (this.props.fieldData.type === "hiddenField") {
            this.props.getFieldValue(this.props.token.access, this.props.fieldData.template, this.props.fieldData.id)
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.hiddenfieldValue !== this.props.hiddenfieldValue) {
            this.state.hiddenfieldValue = this.props.hiddenfieldValue[0]?.deafult_value;
        }
    }

    validateForm(validationObj, value) {
        if (validationObj === null) {
            return
        } else {
            if (!validator(validationObj, value) && value.length > 0) {
                return validationObj.error_message;
            }
        }
        return
    }

    renderFields(fieldData, index, value) {
        switch (fieldData.type) {
            case FIELD_VALUE_MAPPING.TEXT:
                return (
                    <Col key={index} md={3}>
                        <Form.Label>{fieldData.label} {fieldData.mandatory ? <span className='text-danger'>*</span> : null}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={fieldData.placeholder}
                            required={fieldData.mandatory}
                            value={value}
                            disabled={fieldData.disabled}
                            title={fieldData.tooltip}
                            onBlur={(data) => this.props.onBlur(data.target.value)}
                            onChange={event => { this.props.handleDataChange(fieldData.id, event.target.value); }} />
                        <Form.Text className='text-danger'>{this.validateForm(fieldData.validation, value)}</Form.Text>
                    </Col>
                )

            case FIELD_VALUE_MAPPING.PASSWORD:
                return (
                    <Col key={index} md={3}>
                        <Form.Label>{fieldData.label} {fieldData.mandatory ? <span className='text-danger'>*</span> : null}</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder={fieldData.placeholder}
                            required={fieldData.mandatory}
                            value={value}
                            title={fieldData.tooltip}
                            onBlur={(data) => this.props.onBlur(data.target.value)}
                            onChange={event => { this.props.handleDataChange(fieldData.id, event.target.value); }} />
                        <Form.Text className='text-danger'>{this.validateForm(fieldData.validation, value)}</Form.Text>
                    </Col>
                )

            case FIELD_VALUE_MAPPING.EMAIL:
                return (
                    <Col key={index} md={3}>
                        <Form.Label>{fieldData.label} {fieldData.mandatory ? <span className='text-danger'>*</span> : null}</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder={fieldData.placeholder}
                            required={fieldData.mandatory}
                            value={value}
                            title={fieldData.tooltip}
                            onBlur={(data) => this.props.onBlur(data.target.value)}
                            onChange={event => { this.props.handleDataChange(fieldData.id, event.target.value); }} />
                        <Form.Text className='text-danger'>{this.validateForm(fieldData.validation, value)}</Form.Text>
                    </Col>
                )

            case FIELD_VALUE_MAPPING.NUMBER:
                return (
                    <Col key={index} md={3}>
                        <Form.Label>{fieldData.label} {fieldData.mandatory ? <span className='text-danger'>*</span> : null}</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder={fieldData.placeholder}
                            required={fieldData.mandatory}
                            value={value}
                            disabled={fieldData.disabled}
                            onBlur={(data) => this.props.onBlur(data.target.value)}
                            title={fieldData.tooltip}
                            onChange={event => { this.props.handleDataChange(fieldData.id, event.target.value); }} />
                        <Form.Text className='text-danger'> {this.validateForm(fieldData.validation, value)}</Form.Text>
                    </Col>
                )
            case FIELD_VALUE_MAPPING.TEXTAREA:
                return (
                    <Col key={index} md={3}>
                        <Form.Label>{fieldData.label} {fieldData.mandatory ? <span className='text-danger'>*</span> : null}</Form.Label>
                        <Form.Control
                            type="text"
                            as="textarea"
                            rows={3}
                            placeholder={fieldData.placeholder}
                            required={fieldData.mandatory}
                            value={value}
                            disabled={fieldData.disabled}
                            onBlur={(data) => this.props.onBlur(data.target.value)}
                            title={fieldData.tooltip}
                            onChange={event => { this.props.handleDataChange(fieldData.id, event.target.value); }} />
                        <Form.Text className='text-danger'> {this.validateForm(fieldData.validation, value)}</Form.Text>
                    </Col>

                )
            case FIELD_VALUE_MAPPING.DATE:
                return (
                    <Col key={index} md={2}>
                        <Form.Label>{fieldData.label} {fieldData.mandatory ? <span className='text-danger'>*</span> : null}</Form.Label>
                        <Form.Control
                            type="date"
                            placeholder={fieldData.placeholder}
                            required={fieldData.mandatory}
                            defaultValue={value}
                            disabled={fieldData.disabled}
                            onBlur={(data) => this.props.onBlur(data.target.value)}
                            title={fieldData.tooltip}
                            onChange={event => { this.props.handleDataChange(fieldData.id, event.target.value); }} />
                        <Form.Text className='text-danger'> {this.validateForm(fieldData.validation, value)}</Form.Text>
                    </Col>
                )
            case FIELD_VALUE_MAPPING.DATETIME:
                return (
                    <Col key={index} md={2}>
                        <Form.Label>{fieldData.label} {fieldData.mandatory ? <span className='text-danger'>*</span> : null}</Form.Label>
                        <Form.Control
                            type="datetime-local"
                            placeholder={fieldData.placeholder}
                            required={fieldData.mandatory}
                            defaultValue={value}
                            disabled={fieldData.disabled}
                            onBlur={(data) => this.props.onBlur(data.target.value)}
                            title={fieldData.tooltip}
                            onChange={event => { this.props.handleDataChange(fieldData.id, event.target.value); }} />
                        <Form.Text className='text-danger'> {this.validateForm(fieldData.validation, value)}</Form.Text>
                    </Col>
                )

            case FIELD_VALUE_MAPPING.IMAGE:
                return (
                    <Col key={index} md={3}>
                        <Form.Label>{fieldData.label} {fieldData.mandatory ? <span className='text-danger'>*</span> : null}</Form.Label>
                        <FileUpload
                            accept={'image/png, image/gif, image/jpeg'}
                            value={value}
                            title={fieldData.tooltip}
                            ShareToken={this.props.Sharetoken}
                            onChange={(uploadId) => { this.props.handleDataChange(fieldData.id, uploadId); }} />
                        <Form.Text className='text-danger'> {this.validateForm(fieldData.validation, value)}</Form.Text>
                    </Col>
                )

            case FIELD_VALUE_MAPPING.FILE:
                return (
                    <Col key={index} md={3}>
                        <Form.Label>{fieldData.label} {fieldData.mandatory ? <span className='text-danger'>*</span> : null}</Form.Label>
                        <FileUpload
                            accept={'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf'}
                            value={value}
                            fieldData={fieldData}
                            onOCR_View={(id, data) => { this.props.handleDataChange(id, data); }}
                            title={fieldData.tooltip}
                            ShareToken={this.props.Sharetoken}
                            onChange={(uploadId) => { this.props.handleDataChange(fieldData.id, uploadId); }} />
                        <Form.Text className='text-danger'> {this.validateForm(fieldData.validation, value)}</Form.Text>
                    </Col>
                )
            case FIELD_VALUE_MAPPING.DROPDOWN:
                return (
                    <Col key={index} md={3}>
                        <Form.Label>{fieldData.label} {fieldData.mandatory ? <span className='text-danger'>*</span> : null}</Form.Label>
                        <Dropdown
                            fieldData={fieldData}
                            selected={value}
                            disabled={fieldData.disabled}
                            title={fieldData.tooltip}
                            ShareToken={this.props.Sharetoken}
                            onChange={(selectedData) => this.props.handleDataChange(fieldData.id, selectedData)} />
                    </Col>
                )
            case FIELD_VALUE_MAPPING.RADIO:
                return (
                    <Col key={index} md={3}>
                        <Form.Label>{fieldData.label} {fieldData.mandatory ? <span className='text-danger'>*</span> : null}</Form.Label>
                        <RadioButton
                            fieldData={fieldData}
                            value={value}
                            disabled={fieldData.disabled}
                            title={fieldData.tooltip}
                            onChange={(selectedData) => this.props.handleDataChange(fieldData.id, selectedData)} />
                    </Col>
                )
            case FIELD_VALUE_MAPPING.CHECKBOX:
                return (
                    <Col key={index} md={3}>
                        <Form.Label>{fieldData.label} {fieldData.mandatory ? <span className='text-danger'>*</span> : null}</Form.Label>
                        <Checkbox
                            fieldData={fieldData}
                            value={value}
                            disabled={fieldData.disabled}
                            title={fieldData.tooltip}
                            onChange={(selectedData) => this.props.handleDataChange(fieldData.id, selectedData)} />
                    </Col>
                )
            case FIELD_VALUE_MAPPING.HIDDEN_FIELD:
                return (
                    <Col key={index} md={3}>
                        <Form.Label>{fieldData.label} {fieldData.mandatory ? <span className='text-danger'>*</span> : null}</Form.Label>
                        <Form.Control
                            type="text"
                            disabled
                            placeholder={fieldData.placeholder}
                            required={fieldData.mandatory}
                            value={this.state.hiddenfieldValue}
                            title={fieldData.tooltip}
                            onBlur={(data) => this.props.onBlur(data.target.value)}
                            onChange={event => { this.props.handleDataChange(fieldData.id, event.target.value); }} />
                        <Form.Text className='text-danger'>{this.validateForm(fieldData.validation, value)}</Form.Text>
                    </Col>
                )
            case FIELD_VALUE_MAPPING.TABLE:
                return (
                    <Col key={index} md={11}>
                        <h6 title={fieldData.tooltip} className="d-flex flex-row mt-2 mb-2" style={{ fontWeight: "600", fontSize: 17 }}>{fieldData.label} </h6>
                        <Table
                            disabled={false}
                            value={value}
                            data={this.props.fieldData}
                            onChange={(selectedData) =>
                                this.props.handleDataChange(fieldData.id, selectedData)
                            }
                        />
                    </Col>
                )
            default:
                return <p key={index}>{fieldData.type}</p>
        }
    }

    render() {
        return (
            <>
                {this.renderFields(this.props.fieldData, this.props.index, this.props.value)}
            </>
        );
    }
}   

const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
        hiddenfieldValue: state.formsReducer.hiddenfieldValue
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getFieldValue: (accessToken, templateId, fieldId) => dispatch(fetchFieldValue(accessToken, templateId, fieldId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldHandler);