import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createGroup, deleteGroup, fetchAllGroups } from '../action';
import DataTable from '../../common/components/DataTable';
import DeleteModal from '../../common/components/DeleteModal';
import AssignWorkflowModal from './AssignWorkflowModal';
import AssignTemplate from './AssignTemplate';
import { fetchFormsList } from '../../forms/action';

const Group = (props) => {
    const [groupName, setGroupName] = useState('');
    const [templates, setTemplates] = useState("");
    const [showDeleteModal, setshowDeleteModal] = useState(false);
    const [selectedData, setselectedData] = useState(null); // Initialize as null or empty object
    const [selectedGroupId, setSelectedGroupId] = useState(null);
const[state,setState]=useState({
    Modal:false,
    AssignTemplateModal:false
})


useEffect(() => {
    if (props.token?.access) {
        props.getFormList(props.token.access);
    }
}, [props.token?.access]);

const templateChange = (e) => {
    const selectedTemplate = e.target.value;
    setTemplates(selectedTemplate);

};
    // Handle form submit
    const handleCreateAction = (e) => {
        e.preventDefault();
        const { token, createGroup } = props;

        // Ensure token exists before proceeding
        if (token && token.access) {
            createGroup(token.access, { group_name: groupName,template:templates });
            setGroupName('');  // Reset the form after submission
        } else {
            console.error('Token is missing or invalid');
        }
    };

    useEffect(() => {
        if (props.token?.access) {
            props.fetchAllGroups(props.token.access);
        }
    }, [props.token?.access, props.fetchAllGroups]);

    const deleteAction = (event) => {
        props.deleteAction(props.token.access, event.id);
        setselectedData(null); // Reset selectedData after deletion
    };

    console.log(selectedData, 'groups');
    return (
        <div className="main-content-container p-4 container-fluid">
            <Row>
                <Col>
                    <p>Group Management</p>
                    <Card>
                        <Card.Body className="font-medium">
                            <Form onSubmit={handleCreateAction}>
                                <Row>
                                    <Col className="m-4">
                                        <Form.Label>
                                            Group Name <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            placeholder="Enter Group Name"
                                            required
                                            value={groupName}
                                            onChange={(e) => setGroupName(e.target.value)}
                                        />
                                    </Col >
                                    <Col className="m-4">
                                        <Form.Label>
                                           Template <span className="text-danger">*</span>
                                        </Form.Label>
                                    <Form.Select
                    placeholder="Select a Template"
                    aria-label="Default select example"
                    style={{
                        width: '100%',
                        padding: '0.375rem 0.75rem',
                        border: '1px solid #ced4da',
                        color: '#495057',
                        marginBottom: '15px',
                    }}
                    value={templates}
                    onChange={templateChange}
                >
                    <option value="">Select Template</option>
                    {props.formList?.data?.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.label}
                        </option>
                    ))}
                </Form.Select>
                </Col >
                                    <Col className="m-5">
                                        <Button type="submit" variant="outline-success" size="sm">
                                            CREATE
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>

                            <div className="mt-3 font-medium">
                                {props.groups.results && props.groups.results.length > 0 && (
                                    <DataTable
                                        totalItemsCount={props.groups.count}
                                        headers={['Group Name', 'Action']}
                                        body={props.groups.results.map((row, index) => {
                                            return [
                                                row.group_name,
                                                <Row key={index}>
                                                    <Col md="1">
                                                        <Button
                                                            title="Delete"
                                                            className="m-1"
                                                            variant="danger"
                                                            size="sm"
                                                            onClick={() => {
                                                                setshowDeleteModal(true);
                                                                setselectedData(row); // Set selected data correctly
                                                            }}
                                                        >
                                                            <i
                                                                className="fa fa-trash-o"
                                                                aria-hidden="true"
                                                                style={{
                                                                    fontSize: '15px',
                                                                    color: 'white',
                                                                    padding: '2px 3px',
                                                                }}
                                                            ></i>
                                                        </Button>
                                                    </Col>
                                                    <Col md={1}>
                                                    <Button title='Assign Workflow'   className="m-1"  size="sm" onClick={() => {
                                                                setState({ Modal: true });
                                                                setSelectedGroupId(row.id); // Set the selected groupId
                                                            }} variant='success'><i className="fa-solid fa-file-circle-check" style={{
                                                                fontSize: '15px',
                                                                color: 'white',
                                                                padding: '2px 3px',
                                                            }}></i></Button>
                                                    </Col>
                                                    <Col md={1}>
                                                    <Button title='Assign Template'   className="m-1"  size="sm" onClick={() => {
                                                                setState({ AssignTemplateModal: true });
                                                                setSelectedGroupId(row.id); // Set the selected groupId
                                                            }} variant='info'><i className="fa-solid fa-file-circle-check" style={{
                                                                fontSize: '15px',
                                                                color: 'white',
                                                                padding: '2px 3px',
                                                            }}></i></Button>
                                                    </Col>
                                                </Row>,
                                            ];
                                        })}
                                    />
                                )}
                            </div>
                            <AssignWorkflowModal showModal={state.Modal} handleClose={() => setState({ Modal: false })}  groupId={selectedGroupId}/> 
                            <AssignTemplate showModal={state.AssignTemplateModal} handleClose={() => setState({ AssignTemplateModal: false })}  groupId={selectedGroupId}/> 
                           
                            <DeleteModal
                                modalText="Delete Group ?"
                                showModal={showDeleteModal}
                                handleClose={() => setshowDeleteModal(false)}
                                deleteAction={(status) => {
                                    if (status !== undefined && status === true) {
                                        deleteAction(selectedData); // Pass selectedData to delete
                                    }
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state) => ({
    token: state.usersReducer.token,
    groups: state.groupReducer.groups,
    formList: state.formsReducer.formList,
});

const mapDispatchToProps = (dispatch) => ({
    getFormList: (accessToken, searchQuery, pageNumber) => dispatch(fetchFormsList(accessToken, searchQuery, pageNumber)),
    createGroup: (accessToken, requestBody) => dispatch(createGroup(accessToken, requestBody)),
    fetchAllGroups: (accesstoken) => dispatch(fetchAllGroups(accesstoken)),
    deleteAction: (accessToken, groupid) => dispatch(deleteGroup(accessToken, groupid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Group);
