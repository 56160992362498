import { fetchApi } from "../../lib/utils";
import { REQUEST_METHOD, BASE_URL, RESPONSE_TYPE } from "../../lib/constants";
import { FETCH_ALL_GROUP, FETCH_TEAM, FETCH_TEAM_MEMBERS } from "./actionTypes";
import { LOADING_START, LOADING_END } from "../common/actionTypes";
import { setNotification } from "../common/action";

export function fetchAllGroups(accessToken) {
    return function (dispatch) {
        dispatch({ type: LOADING_START });
        fetchApi(`/forms/notification-groups/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                console.log(successResponse,'successResponse')
                dispatch({ type: LOADING_END })
                dispatch({ type: FETCH_ALL_GROUP, payload: successResponse })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            })
    }
}

export function createGroup(accessToken, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/forms/notification-groups/`,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch(setNotification(true, "Group Created successfully", "success"));
                dispatch(fetchAllGroups(accessToken))
                dispatch({ type: LOADING_END })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody)
    }
}
export function deleteGroup(accessToken, groupid) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/forms/notification-groups/${groupid}/`,
            accessToken,
            REQUEST_METHOD.DELETE,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Groups deleted Successfully', 'danger'))
                dispatch(fetchAllGroups(accessToken))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            null, RESPONSE_TYPE.NULL
        )
    }
}


export function createAssignWorkflow(accessToken, requestBody,fun) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/forms/group-workflowmembers/create/`,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch(setNotification(true, "Data Added successfully", "success"));
                fun()
            dispatch(fetchAllAssignWorkflowDetails(accessToken,requestBody.requestBody.group_id  ))
                 dispatch({ type: LOADING_END })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody)
    }
}

export function fetchAllAssignWorkflowDetails(accessToken,id,fun) {
    return function (dispatch) {
        dispatch({ type: LOADING_START });
        fetchApi(`/forms/group-workflowmembers/${id}/details/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                console.log(successResponse,'successResponse')
                dispatch({ type: LOADING_END })
               fun(successResponse)
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            })
    }
}

export function deleteAssignWorkflowDetails(accessToken, groupid,tempid,fun) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/forms/group-workflowmembers/${groupid}/${tempid}/delete/
        `,
            accessToken,
            REQUEST_METHOD.DELETE,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Data deleted Successfully', 'danger'))
                dispatch(fetchAllAssignWorkflowDetails(accessToken,groupid,fun))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            null, RESPONSE_TYPE.NULL
        )
    }
}

export function createAssignTemplate(accessToken, requestBody,fun) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/forms/group_template/create/`,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch(setNotification(true, "Data Added successfully", "success"));
            
            dispatch(fetchAllAssignTemplate(accessToken,requestBody.group,fun  ))
                 dispatch({ type: LOADING_END })

            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody)
    }
}

export function fetchAllAssignTemplate(accessToken,id,fun) {
    return function (dispatch) {
        dispatch({ type: LOADING_START });
        fetchApi(`/forms/group_template_get/${id}/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                console.log(successResponse,'successResponse')
                dispatch({ type: LOADING_END })
               fun(successResponse)
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            })
    }
}

export function deleteAssignTemplate(accessToken, id,groupId,fun) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/forms/group_template_delete/${id}/
        `,
            accessToken,
            REQUEST_METHOD.DELETE,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Data deleted Successfully', 'danger'))
                dispatch(fetchAllAssignTemplate(accessToken,groupId,fun  ))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            null, RESPONSE_TYPE.NULL
        )
    }
}