import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { updateCustomForms, updateForms } from '../../forms/action';

class UpdateCustomFormTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            label: '',
            description: '',
            image_header: null,
            image_footer: null,
            timer: false,
            timmer: 0,
            lock_status:false,
            data_secure:false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedData !== this.props.selectedData) {
            this.setState({ label: this.props.selectedData.label, description: this.props.selectedData.description, image_header: this.props.selectedData.image_header, image_footer: this.props.selectedData.image_footer, timer: this.props.selectedData.timmer == 0 ? false : true, timmer: this.props.selectedData.timmer, ...this.props.selectedData })
        }
    }

    componentDidMount() {
        this.setState({ label: this.props.selectedData.label, description: this.props.selectedData.description, image_header: this.props.selectedData.image_header, image_footer: this.props.selectedData.image_footer, timer: this.props.selectedData.timmer == 0 ? false : true, timmer: this.props.selectedData.timmer, ...this.props.selectedData })
    }

    handleUpdateAction(event) {
        event.preventDefault()
        var formdata = new FormData()
        if (this.state.label !== this.props.selectedData.label) {
            formdata.append("label", this.state.label);
        }
        if (this.state.description !== this.props.selectedData.description) {
            formdata.append("description", this.state.description);
        }
        if (this.state.image_header !== this.props.selectedData.image_header) {
            formdata.append("image_header", this.state.image_header);
        }
        if (this.state.image_footer !== this.props.selectedData.image_footer) {
            formdata.append("image_footer", this.state.image_footer);
        }
        if (this.state.timmer !== this.props.selectedData.timmer) {
            formdata.append("timmer", this.state.timmer);
        }
        if (this.state.data_secure !== this.props.selectedData.data_secure) {
            formdata.append("data_secure", this.state.data_secure);
        }
        if (this.state.lock_status !== this.props.selectedData.lock_status) {
            formdata.append("lock_status", this.state.lock_status);
        }
        // var updated_body = {
        //     form_id: this.props.selectedData.id,
        //     custom_template_type: this.props.selectedData.custom_template_type,
        //     active: true,
        //     custom_form_data: this.state
        // }

        this.props.handleClose();

        this.props.editForms(this.props.token.access, this.props.selectedData.id, formdata, this.state);

    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title >Update Template</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className='font-medium'>
                        <Form.Group className="mb-3">
                            <Form.Label>Form Name <span className='text-danger'>*</span></Form.Label>
                            <Form.Control size='sm' type="text" placeholder="Enter Form Name" required
                                onChange={event => { this.setState({ label: event.target.value }); }}
                                value={this.state.label} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control size='sm' type="text" placeholder="Enter Description"
                                onChange={event => { this.setState({ description: event.target.value }); }}
                                value={this.state.description} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Header Banner</Form.Label>
                            <Form.Control size='sm' type="file"
                                onChange={event => { this.setState({ image_header: event.target.files[0] }) }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Footer Banner</Form.Label>
                            <Form.Control size='sm' type="file"
                                onChange={event => { this.setState({ image_footer: event.target.files[0] }) }} />
<Row>
    <Col md={4}>
                            {this.state.timer == false ?
                                <Form.Group className="mb-3">
                                    <Form.Label>Timer</Form.Label>
                                    <Form.Check type="switch" label="Add Timer?" checked={this.state.timer}
                                        onChange={() => this.setState({ timer: !this.state.timer })} />
                                </Form.Group> :
                                <Form.Group className="mb-3">
                                    <Form.Label>Timer</Form.Label>
                                    <Form.Control size="sm" type="number" placeholder="Enter Time In Sec" required value={this.state.timmer}
                                        onChange={event => { this.setState({ timmer: event.target.value }); }} />
                                </Form.Group>
                            }
                            </Col>
                                <Col md={4}>
        
        <Form.Group className="mb-2">
                                                    <Form.Label>Auto Lock</Form.Label>
                                                    <Form.Check type="switch" label="In Auto Lock?" checked={this.state.lock_status}
                                                        onChange={() => this.setState({ lock_status: !this.state.lock_status })} />
                                              </Form.Group>
            </Col>
            <Col md={4}>
            <Form.Group className="mb-2">
                                                    <Form.Label>Data Secure</Form.Label>
                                                    <Form.Check type="switch" label="In Data Secure?" checked={this.state.data_secure}
                                                        onChange={() => this.setState({ data_secure: !this.state.data_secure })} />
                                                </Form.Group>
            </Col>
            </Row>
                        </Form.Group><Form.Group className="mt-3 d-flex justify-content-end" >
                            <Button variant="warning" size='sm' onClick={(e) => { this.handleUpdateAction(e) }} >UPDATE</Button>
                        </Form.Group>

                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        formList: state.formsReducer.formList,
        token: state.usersReducer.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        editForms: (accessToken, pk, formdata, updatedData) => dispatch(updateForms(accessToken, pk, formdata, updatedData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCustomFormTemplate);
