
import { FETCH_ALL_CUSTOMLINK } from "./actionType";

const initialState = {
    // teams: DEFAULT_DATA,
    // teamData: {},
    // teamMembers: DEFAULT_DATA
    customlink:{}
};

const customLinkReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_CUSTOMLINK:
            return {
                ...state,
                customlink: action.payload
            };
       

        default:
            return state;
    }
};

export default customLinkReducer;
