import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { deleteField, updateFieldForms } from '../action';
import { FIELDS, VALIDATORS, UNOIQUE_TYPE } from '../../../lib/constants';
import { checkDataSourceFieldType, checkHiddenField, getHiddenDeleteField } from '../../../lib/utils';
import { setNotification } from '../../common/action';
import OCRModal from "../../common/components/OcrRemoveModal"
class FormFieldUpdateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            label: '',
            type: '',
            mandatory: false,
            mainView: true,
            sequence: '',
            placeholder: '',
            tooltip: '',
            dataSource: 'string',
            validationType: null,
            validationMessage: null,
            validationExpression: null,
            check_unique: false,
            unique_type: 1,
            auto_sum: false,
            defaultValue: '',
            ocr_view: false,
            showDeleteModal: false,
            send_alert:false,
            lower_value:"",
            higher_value:"",
            initial:"True"
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedData !== this.props.selectedData) {
            let validationType = "";
            let validationMessage = "";
            if (this.props.selectedData.validation !== null) {
                validationType = this.props.selectedData.validation.type
                validationMessage = this.props.selectedData.validation.error_message
            }
            this.setState({
                label: this.props.selectedData.label,
                type: this.props.selectedData.type,
                mandatory: this.props.selectedData.mandatory,
                mainView: this.props.selectedData.display_in_main_view,
                sequence: this.props.selectedData.sequence,
                placeholder: this.props.selectedData.placeholder,
                tooltip: this.props.selectedData.tooltip,
                dataSource: this.props.selectedData.master_data_code ? this.props.selectedData.master_data_code : 'string',
                validationType: validationType,
                validationMessage: validationMessage,
                check_unique: this.props.selectedData.check_unique,
                unique_type: this.props.selectedData.unique_type,
                auto_sum: this.props.selectedData.auto_sum,
                defaultValue: this.props.selectedData.deafult_value,
                ocr_view: this.props.selectedData.ocr_view,
                send_alert:this.props.selectedData.send_alert,
                lower_value:this.props.selectedData.lower_value,
                higher_value:this.props.selectedData.higher_value,
                initial:this.props.selectedData.initial
            })
        }
    }
    ocrAlert() {
        if (this.state.ocr_view == false) {
            this.setState({ showDeleteModal: true })
        }
    }
    handleUpdateAction(event) {
        event.preventDefault();
        let ValidationFlag = true;
        let requestBody = {
            "label": this.state.label,
            "type": this.state.type,
            "mandatory": this.state.mandatory,
            "sequence": this.state.sequence,
            "template": this.props.selectedData.template,
            "display_in_main_view": this.state.mainView,
            "placeholder": this.state.placeholder,
            "tooltip": this.state.tooltip,
            "check_unique": this.state.check_unique,
            "unique_type": this.state.unique_type,
            "auto_sum": this.state.auto_sum,
            "master_data_code": this.state.dataSource,
            "is_delete": false,
            "check_unique": this.state.check_unique,
            "unique_type": this.state.unique_type,
            "auto_sum": this.state.auto_sum,
            "ocr_view": this.state.ocr_view,
            "validation": {
                "type": this.state.validationType,
                "expression": this.state.validationExpression,
                "error_message": this.state.validationMessage
            },
            "send_alert":this.state.send_alert,
            "higher_value":this.state.higher_value,
            "lower_value":this.state.lower_value,
            "initial":this.state.initial
        }
        if (ValidationFlag && requestBody["label"].replace(/\s/g, '').length) {
            this.props.handleClose();
            this.props.editFieldForms(this.props.token.access, this.props.selectedData.id, requestBody, this.props.formId);
        }
        else {
            this.props.setNotification(true, "Please Provide Correct Information", "danger")
        }
    }

    render() {
        return (
            <>
                <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static" keyboard={false} centered={true}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Template</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={(e) => { this.handleUpdateAction(e) }} >
                            <Row>
                                <Col md={6} className='mb-3'>
                                    <Form.Label>Field Name <span className='text-danger'>*</span></Form.Label>
                                    <Form.Control size='sm' type="text" placeholder="Enter Form Name" required
                                        onChange={event => { this.setState({ label: event.target.value }); }}
                                        value={this.state.label} />
                                </Col>
                                <Col md={6} className='mb-3' >
                                    <Form.Label>Placeholder </Form.Label>
                                    <Form.Control size='sm' type="text" placeholder="Enter Placeholder"
                                        onChange={event => { this.setState({ placeholder: event.target.value }); }} value={this.state.placeholder} />
                                </Col>
                                <Col md={6} className='mb-3' >
                                    <Form.Label>ToolTip </Form.Label>
                                    <Form.Control size='sm' type="text" placeholder="Enter Placeholder"
                                        onChange={event => { this.setState({ tooltip: event.target.value }); }} value={this.state.tooltip} />
                                </Col>
                                <Col md={6} className='mb-3'>
                                    <Form.Label>Type <span className='text-danger'>*</span></Form.Label>
                                    <Form.Select size='sm' aria-label="Default select example" onChange={(event) => this.setState({ type: event.target.value })} value={this.state.type} required>
                                        <option>-Select Field Type-</option>
                                        {FIELDS.map((row, index) => {
                                            return (
                                                <option value={row.value} key={index}>{row.label}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </Col>
                                {checkDataSourceFieldType(this.state.type) ? <Col md={6}>
                                    <Form.Label>Data Source Form <span className='text-danger'>*</span></Form.Label>
                                    <Form.Select size='sm' aria-label="Default select example" onChange={(event) => this.setState({ dataSource: event.target.value })} required defaultValue={this.state.dataSource} >
                                        <option>-Select Field Type-</option>
                                        {this.props.formList.data.map((row, index) => {
                                            return (
                                                <option value={row.id} key={index}>{row.label}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </Col> : null}
                                {checkHiddenField(this.state.type) ?
                                    <Col md={6}>
                                        <Form.Label>Default Value <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control size='sm' type="text" placeholder="Set default value for hidden field" required value={this.state.defaultValue}
                                            onChange={event => { this.setState({ defaultValue: event.target.value }); }} />
                                    </Col> : null}


                                    
                             <Col md={6}>
                                        <Form.Label>Initial<span className='text-danger'>*</span></Form.Label>
                                
                                <div>
                                    <Form.Select value={this.state.initial}  onChange={(event) => this.setState({ initial: event.target.value })}>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </Form.Select>
                                </div>
                                </Col>
                                        <Col md={2}>
                                            <Form.Label>Show Alert</Form.Label>
                                            <Form.Check type="switch" label="" checked={this.state.send_alert}
                                                onChange={() => this.setState({ send_alert: !this.state.send_alert })} />
                                        </Col>
                                        <Col md={4} className='mb-3'>
                                    <Form.Label>Mandatory</Form.Label>
                                    <Form.Check type="switch" checked={this.state.mandatory} label="Is this field mandatory?"
                                        onChange={() => this.setState({ mandatory: !this.state.mandatory })} />
                                </Col>
                                        {this.state.send_alert && <>
                                            <Col md={3} > <Form.Label>Lower Value</Form.Label>
                                 <Form.Control type="text" placeholder={'Enter Lower Value'}
                                     defaultValue={this.state.lower_value}
                                     onChange={(event) => {
                                         this.setState({ lower_value: event.target.value })
                                     }} />
                            </Col>
                         <Col md={3} >
                             <Form.Label>Higher Value</Form.Label>
                                 <Form.Control type="text" placeholder={'Enter Higher Value'}
                                     defaultValue={this.state.higher_value}
                                     onChange={(event) => {
                                         this.setState({ higher_value: event.target.value })
                                     }} />
                             </Col>
                                        </>}

                               
                                <Col md={6} className='mb-3'>
                                    <Form.Label>Display in Main View </Form.Label>
                                    <Form.Check type="switch" label="Display in Main View?" checked={this.state.mainView}
                                        onChange={() => this.setState({ mainView: !this.state.mainView })} />
                                </Col>
                                <Col md={6} className='mb-3'>
                                    <Form.Label>Sequence <span className='text-danger'>*</span></Form.Label>
                                    <Form.Control size='sm' type="number" placeholder="Sequence" value={this.state.sequence} required
                                        onChange={event => { this.setState({ sequence: event.target.value }); }} />
                                </Col>
                                {checkDataSourceFieldType(this.state.type) || checkHiddenField(this.state.type) ? null :
                                    <>
                                        <Col md={6}>
                                            <Form.Label>Validators</Form.Label>
                                            <Form.Select size='sm' aria-label="Default select example" value={this.state.validationType} onChange={(event) => this.setState({ validationType: event.target.value })}>
                                                <option value={null}>-Select Validator Type-</option>
                                                {VALIDATORS.map((row, index) => {
                                                    return (
                                                        <option value={row.value} key={index}>{row.label}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Label>Validation Error Message {this.state.validationType == null ? null : <span className='text-danger'>*</span>}</Form.Label>
                                            <Form.Control size='sm' type="text" placeholder="Error Message"
                                                required={this.state.validationType !== null}
                                                value={this.state.validationMessage}
                                                onChange={event => { this.setState({ validationMessage: event.target.value }); }} />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Check Unique</Form.Label>
                                            <Form.Check type="switch" label="Is the field value unique?" checked={this.state.check_unique}
                                                onChange={() => this.setState({ check_unique: !this.state.check_unique })} />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Unique Type</Form.Label>
                                            <Form.Select size='sm' value={this.state.unique_type} onChange={(event) => this.setState({ unique_type: event.target.value })} >
                                                {UNOIQUE_TYPE.map((row, index) => {
                                                    return (
                                                        <option value={row.value} key={index}>{row.label}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Auto Sum</Form.Label>
                                            <Form.Check type="switch" label="Calculate Sum?" checked={this.state.auto_sum}
                                                onChange={() => this.setState({ auto_sum: !this.state.auto_sum })} />
                                        </Col>
                                        {this.props.selectedData.type == "file" ?
                                            <Col md={3}>
                                                <Form.Label>Need OCR?</Form.Label>
                                                <Form.Check type="switch" label="Need OCR?" checked={this.state.ocr_view}
                                                    onChange={() => {
                                                        this.setState({ ocr_view: !this.state.ocr_view }); setTimeout(() => {
                                                            this.ocrAlert()
                                                        }, 0);
                                                    }} />
                                            </Col> : <span></span>
                                        }
                                    </>}
                            </Row>
                            <Row>
                                <Col md={12} className='d-flex justify-content-end'>
                                    <Button type='submit' variant="outline-success" size='md' className='mt-2 me-4'>UPDATE</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                </Modal>
                <OCRModal modalText={"If you remove the OCR, it might delete some fields."} showModal={this.state.showDeleteModal} handleClose={() => this.setState({ showDeleteModal: false, ocr_view: this.props.selectedData.ocr_view })}
                    deleteAction={(status) => {
                        this.setState({ ocr_view: status, showDeleteModal: false });
                        if (status == false) {
                            this.props.deleteField(this.props.token.access, getHiddenDeleteField(this.props.selectedData.id, this.props.fieldsData).id, this.props.formId, false, () => { })
                        }
                    }}
                />
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        formList: state.formsReducer.formList,
        token: state.usersReducer.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        editFieldForms: (accessToken, pk, requestBody, formId) => dispatch(updateFieldForms(accessToken, pk, requestBody, formId)),
        setNotification: (show, text, color) => dispatch(setNotification(show, text, color)),
        deleteField: (accessToken, fieldId, formId, OCR_VIEW, afterDelet) => dispatch(deleteField(accessToken, fieldId, formId, OCR_VIEW, afterDelet)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormFieldUpdateModal);
