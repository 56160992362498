import { combineReducers } from 'redux';
import commonReducer from '../views/common/reducer';
import formsReducer from '../views/forms/reducer';
import workflowReducer from '../views/workflows/reducer';
import usersReducer from '../views/users/reducer';
import projectReducer from '../views/project/reducer';
import organizationReducer from '../views/organisations/reducer';
import fileExplorerReducer from '../views/fileexplorer/reducer';
import reportsReducer from '../views/reports/reducer';
import logreducer from '../views/logs/reducer';
import teamReducer from '../views/teamManagement/reducer';
import teamReportsReducer from '../views/teamReports/reducer';
import notificationReducer from "../views/notification/reducer";
import billingReducer from '../views/Billing/reducer';
import groupReducer from '../views/groupManagement/reducer';
import customLinkReducer from '../views/customLink/reducer';
const rootReducer = combineReducers({
    commonReducer,
    formsReducer,
    usersReducer,
    workflowReducer,
    projectReducer,
    organizationReducer,
    fileExplorerReducer,
    reportsReducer,
    logreducer,
    teamReducer,
    teamReportsReducer,
    notificationReducer,
    billingReducer,
    groupReducer,
    customLinkReducer

})

export default rootReducer;
