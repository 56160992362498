import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Col, Row, Card, Button } from "react-bootstrap";
import { createInternalUser, createUser, getCustomDashboard, getValueSelectGroup, removeUser } from "../action";
import DataTable from "../../common/components/DataTable";
import { fetchUserList, fetcInternal } from "../../common/action";
import { formateDate } from "../../../lib/utils";
import UserUpdateModal from "./UserUpdateModal";
import DeleteModal from "../../common/components/DeleteModal";
import { Link } from "react-router-dom";
import { fetchAllGroups } from "../../groupManagement/action";

class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpdateModal: false,
      showDeleteModal: false,
      selectedData: {},
      name: "",
      email: "",
      phone: "",
      show: false,
      is_superuser: false,
      search: "",
      dashboardname:{},
      selectedDashboardId: "",
      group:"",
      apidata:{},
      value:'',
      showform:'',
      selectedId:''
    };
  }

  componentDidMount = () => {
    this.props.fetchUserList(this.props.token.access, 1, "");
   

    if (this.props.token.access) { 
      this.props.getCustomDashboard(this.props.token.access, (response) => {
       
        this.setState({ dashboardname: response || [] }); // Set the dashboard data correctly
      });

      this.props.fetchAllGroups(this.props.token.access)
    }
  };
  handleDashboardChange = (e) => {
    
    this.setState({ selectedDashboardId: e.target.value }); // Update the selected dashboard ID
    // Optionally, you can update any other state or trigger additional logic
};


handleGroupChange = (selectedGroupId, template) => {
    
  this.setState({ group: selectedGroupId,template}); // Update the selected dashboard ID

  if(template){
    this.props.getValueSelectGroup(this.props.token.access,template,(response)=> this.setState({apidata:response}))
  }else{
    this.setState((prevdata)=>({...prevdata,apidata:[]}))
  }

  // Optionally, you can update any other state or trigger additional logic
};
  handleSearchAction() {
    this.props.fetchUserList(this.props.token.access, 1, this.state.search);
  }

  handleSignupAction(event) {
    event.preventDefault();
    let requestBody = {
      name: this.state.name,
      phone: this.state.phone,
      email: this.state.email,
      password: this.state.phone,
      is_superuser: this.state.is_superuser,
      dashboard:this.state.selectedDashboardId,
      group:this.state.group,
      field_value:this.state.value,
      show_form:this.state.showform,
      field:parseInt(this.state.selectedId)
    };
    
    this.props.addUser(this.props.token.access, requestBody);
    this.setState({
      name: "",
      email: "",
      phone: "",
      is_superuser: false,
      dashboard:"",
      group:"",
      field_value:"",
      showform:'',
      field:''
    });
  }

  deleteAction(event) {
    this.props.deleteUser(this.props.token.access, event.id);
  }

  render() {

    return (
      <div className="main-content-container p-4 container-fluid">
        <Row>
          <Col>
            <p>Users</p>
            <Card>
              <Card.Body className="font-medium">
                <Form
                  onSubmit={(e) => {
                    this.handleSignupAction(e);
                  }}
                >
                  <Row>
                    <Col md={3}>
                      <Form.Label>
                        User Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        placeholder="Enter User Name"
                        required
                        value={this.state.name}
                        onChange={(event) => {
                          this.setState({ name: event.target.value });
                        }}
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Label>
                        E-Mail<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="email"
                        placeholder="Enter Email"
                        value={this.state.email}
                        required
                        onChange={(event) => {
                          this.setState({ email: event.target.value });
                        }}
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Label>
                        Phone<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        placeholder="Enter Phone Number"
                        required
                        value={this.state.phone}
                        onChange={(event) => {
                          this.setState({ phone: event.target.value });
                        }}
                      />
                      <Form.Text style={{ color: "red" }}>
                        Note:Your phone number will be used as your login password.
                      </Form.Text>
                    </Col>
                   
                    <Col md={3}>
                    <Form.Label>Dashboard</Form.Label>
                    <Form.Select
            value={this.state.selectedDashboardId} // Bind to the selectedDashboardId
            onChange={this.handleDashboardChange} // Handle change event
            
        >
          <option value="">Select A dashboard</option>
            {this.state.dashboardname?.results?.map((data, index) => (
                <option key={index} value={data.id}>
                    {data.dashboard_name}
                </option>
            ))}
        </Form.Select>
                    </Col>
                  
                    <Col md={3}>
                                        <Form.Label>
                                            Group Name
                                        </Form.Label>
                                        <Form.Select
            value={this.state.group} // Bind to the selectedDashboardId
            onChange={(e) => {
              const selectedGroupId = e.target.value; // Get selected group ID
              const selectedGroup = this.props.groups?.results?.find(
                (group) => group.id == selectedGroupId
              ); // Find the selected group object
          console.log(selectedGroupId,selectedGroup?.template)
              // Update state and handle template
              this.handleGroupChange(selectedGroupId, selectedGroup?.template);
            }}
            
        >
          <option value="">Select A Group</option>
            {this.props.groups?.results?.map((data, index) => (
           
                <option key={index} value={data.id}>
                    {data.group_name}
                </option>
            ))}
        </Form.Select>
                                    </Col>
                                    {this.state.apidata?.data?.length > 0 &&    <Col md={3}>
                                        <Form.Label>
                                            value
                                        </Form.Label>
                                        {/* <Form.Select
            value={this.state.value} // Bind to the selectedDashboardId
            onChange={(e) => {
              this.setState({value:e.target.value})
            }}
            
        >
        <option value="">Select</option>
        {this.state.apidata?.data?.map((group, index) => 
    group.data.map((item, idx) => (
      <option key={item.id} value={item.value}>
        {item.value}
      </option>
    ))
  )}

          
        </Form.Select> */}

<Form.Select
  value={this.state.value} // Bind to the selected value
  onChange={(e) => {
    // const [id, value] = e.target.value.split('|'); // Extract id and value
    const selectedOption = e.target.options[e.target.selectedIndex];
    const fieldId = selectedOption.getAttribute('data-field-id');
    const value = e.target.value;
    this.setState({ selectedId: fieldId, value: value }); // Update state with both id and value
  }}
>
  <option value="">Select</option>
  {this.state.apidata?.data?.map((group, index) =>
    group.data.map((item, idx) => (
      <option key={item.id} value={item.value} data-field-id={item.field}>
        {item.value}
      </option>
    ))
  )}
</Form.Select>

                                    </Col>}
                                    <Col md={3}>
                                        <Form.Label>
                                            Show Form
                                        </Form.Label>
                                        <Form.Select
            value={this.state.showform} // Bind to the selectedDashboardId
            onChange={(e) => {
              this.setState({showform:e.target.value})
            }}
            
        >
        <option value="">Select</option>
        <option value="Template">Template</option>
        <option value="Form">Form</option>
        <option value="Both">Both</option>

          
        </Form.Select>
                                    </Col>
                    <Col md={3}>
                      <Form.Label>Super User</Form.Label>
                      <Form.Check
                        type="switch"
                        label="Is the user a Super User ?"
                        checked={this.state.is_superuser}
                        onChange={() =>
                          this.setState({
                            is_superuser: !this.state.is_superuser,
                          })
                        }
                      />
                    </Col>
                    <Col md={2} className="d-flex align-items-end mt-4">
                      <Button type="submit" variant="outline-success" size="sm">
                        CREATE
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <Row className="mt-5 d-flex justify-content-end">
                  <Col md="3">
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder="Search Template"
                      required
                      value={this.state.search}
                      onChange={(event) => {
                        this.setState({ search: event.target.value });
                      }}
                    />
                  </Col>
                  <Col md="1" className="d-flex align-items-end">
                    <Button
                      onClick={() => this.handleSearchAction()}
                      variant="success"
                      size="sm"
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
                <div className="mt-3 font-medium">
                  {this.props.userList && this.props.userList?.length > 0 && (
                    <DataTable
                      totalItemsCount={this.props.userList.count}
                      headers={[
                        `SI No`,
                        `User Name`,
                        `Email`,
                        `Phone`,
                        `Action`,
                      ]}
                      onPageChange={(pageNumber) =>
                        this.props.fetchUserList(
                          this.props.token.access,
                          pageNumber,
                          ""
                        )
                      }
                      body={this.props.userList?.map((row, index) => {
                        
                        return [
                          index + 1,
                          row.name,
                          row.email,
                          row.phone,
                          <Row>
                            <Col sm="12">
                              <Button title="Edit User"
                                variant="info"
                                size="sm"
                                className="m-1"
                                onClick={() => {
                                  this.setState({
                                    showUpdateModal: true,
                                    selectedData: row,
                                  });
                                }}
                              >
                              <i class="fa fa-pencil-square-o" aria-hidden="true" style={{ fontSize: "15px", color: "white",padding:"2px 3px"}}></i>
                              </Button>
                              {/* <Link to={`/forms/${row.id}/fields`} >
                                                                <Button variant="outline-secondary" size='sm' className="m-1">FIELDS</Button>
                                                            </a>
                                                            <Link to={`/forms/${row.id}/view_data`} >
                                                                <Button variant="outline-primary" size='sm' className="m-1">VIEW</Button>
                                                            </a> */}
                              <Link to={`/userlocation/${row.id}`}>
                                <Button title="Track"
                                  variant="primary"
                                  size="sm"
                                  className="m-1"
                                >
                                  <i class="fa-solid fa-location-arrow"></i>
                                </Button>
                              </Link>
                              <Button title="Remove User"
                                onClick={() =>
                                  this.setState({
                                    showDeleteModal: true,
                                    selectedData: row,
                                  })
                                }
                                variant="danger"
                                size="sm"
                                className="m-1"
                              >
                               <i class="fa fa-trash-o" aria-hidden="true" style={{ fontSize: "15px", color: "white",padding:"2px 3px"}}></i>
                              </Button>
                            </Col>
                          </Row>,
                        ];
                      })}
                    />
                  )}
                </div>
                <DeleteModal
                  modalText={"Remove User ?"}
                  showModal={this.state.showDeleteModal}
                  handleClose={() => this.setState({ showDeleteModal: false })}
                  deleteAction={(status) => {
                    if (status !== undefined && status == true) {
                      this.deleteAction(this.state.selectedData);
                    }
                  }}
                  selectedData={this.state.selectedData}
                />
                <UserUpdateModal
                  showModal={this.state.showUpdateModal}
                  handleClose={() => this.setState({ showUpdateModal: false })}
                  selectedData={this.state.selectedData}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.usersReducer.token,
    userList: state.commonReducer.userbillingList,
    groups: state.groupReducer.groups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (token, requestbody) =>
      dispatch(createInternalUser(token, requestbody)),
    fetchUserList: (accessToken, pageNo, search) =>
      dispatch(fetcInternal(accessToken, pageNo, search)),
    deleteUser: (accessToken, userId) =>
      dispatch(removeUser(accessToken, userId)),
      getCustomDashboard: (accessToken,fun) =>
      dispatch(getCustomDashboard(accessToken,fun)),
      fetchAllGroups: (accesstoken) => dispatch(fetchAllGroups(accesstoken)),
      getValueSelectGroup:(accesstoken,tempid,fun)=> dispatch(getValueSelectGroup(accesstoken,tempid,fun))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
