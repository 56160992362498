import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form, Col, Row, } from 'react-bootstrap';
import { getCustomDashboard, getValueSelectGroup, updateUserProfile } from '../action';
import { setNotification } from '../../common/action';
import { fetchAllGroups } from '../../groupManagement/action';


class UserUpdateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            phone: "",
            is_superuser: false,
           
            dashboardname:{},
            selectedDashboardId: "",
            selectedDashboardName: "",
            group:"",
            apidata:{},
            value:'',
            showform:'',
            selectedId:''
        }
    }
componentDidMount(){
    if (this.props.token?.access) { 
        this.props.getCustomDashboard(this.props.token?.access, (response) => {
         
          this.setState({ dashboardname: response || [] }); // Set the dashboard data correctly
        });
        this.props.fetchAllGroups(this.props.token.access)
      }
      
}
handleGroupChange = (selectedGroupId, template) => {
    
    this.setState({ group: selectedGroupId,template}); // Update the selected dashboard ID
   console.log(template)
    if(template){
      this.props.getValueSelectGroup(this.props.token.access,template,(response)=> this.setState({apidata:response}))
    }else{
        this.setState((prevdata)=>({...prevdata,apidata:[]}))
      }
  
    // Optionally, you can update any other state or trigger additional logic
  };
handleDashboardChange = (e) => {
    
    this.setState({ selectedDashboardId: e.target.value }); // Update the selected dashboard ID
    // Optionally, you can update any other state or trigger additional logic
};
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedData !== this.props.selectedData) {
            // const selectedField = this.props.groups?.results?.find(group => group.id === state.field);
            this.setState({
                name: this.props.selectedData.name,
                email: this.props.selectedData.email,
                phone: this.props.selectedData.phone,
                is_superuser: this.props.selectedData.is_superuser,
                selectedDashboardId: this.props.selectedData.dashboard,
                group:this.props.selectedData.group,
                showform:this.props.selectedData.show_form,
                value:this.props.selectedData.field_value,
                selectedId:this.props.selectedData.field
            })
        }
        if (
            (prevState.dashboardname !== this.state.dashboardname || 
             prevState.selectedDashboardId !== this.state.selectedDashboardId) &&
            this.state.selectedDashboardId
        ) {
            const matchingDashboard = this.state.dashboardname?.results?.find(
                (data) => data.id === this.state.selectedDashboardId
            );
            if (matchingDashboard) {
                this.setState({ selectedDashboardName: matchingDashboard.dashboard_name });
            }
        }
    }

    handleUpdateAction(event) {
        event.preventDefault()
        let requestBody = {
            "name": this.state.name,
            "phone": this.state.phone,
            "email": this.state.email,
            "password": this.state.phone,
            "is_superuser": this.state.is_superuser,
            "dashboard":this.state.selectedDashboardId,
            "group":Number(this.state.group),
            "field_value":this.state.value,
            "show_form":this.state.showform,
            "field":parseInt(this.state.selectedId)
        }
        this.props.handleClose();
        if (requestBody["name"].replace(/\s/g, '').length) {
            this.props.editUser(this.props.token.access, requestBody, this.props.selectedData.id);
        }
        else {
            alert('enter valid credentials')
        }
    }

    render() {
      
        return (
            <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title >Update User</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form onSubmit={(e) => { this.handleUpdateAction(e) }} className='font-medium'>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>User Name<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control size='sm' type="text" placeholder="Enter User Name" required
                                        onChange={event => { this.setState({ name: event.target.value }); }}
                                        value={this.state.name} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>E - mail<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control size='sm' type="email" placeholder="Enter e-mail"  disabled
                                        onChange={event => { this.setState({ email: event.target.value }); }}
                                        value={this.state.email} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Phone<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control size='sm' type="number" placeholder="Enter Phone" required
                                        onChange={event => { this.setState({ phone: event.target.value }); }}
                                        value={this.state.phone} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                    <Form.Label>Dashboard</Form.Label>
                    <Form.Select
                                    value={this.state.selectedDashboardId} 
                                    onChange={this.handleDashboardChange} 
                                    
                                >
                                    <option value="">{this.state.selectedDashboardName || "Select A dashboard"}</option>
                                    {this.state.dashboardname?.results?.map((data) => (
                                        <option key={data.id} value={data.id}>
                                            {data.dashboard_name}
                                        </option>
                                    ))}
                                </Form.Select>
                   
                    </Col>
                    <Col md={6}>
                                        <Form.Label>
                                            Group Name 
                                        </Form.Label>
                                        <Form.Select
            value={this.state.group} // Bind to the selectedDashboardId
            onChange={(e) => {
                const selectedGroupId = e.target.value; // Get selected group ID
                const selectedGroup = this.props.groups?.results?.find(
                  (group) => group.id == selectedGroupId
                ); 
                this.handleGroupChange(selectedGroupId, selectedGroup?.template);
              }}
            
        >
          <option value="">Select A Group</option>
            {this.props.groups?.results?.map((data, index) => (
                <option key={index} value={data.id}>
                    {data.group_name}
                </option>
            ))}
        </Form.Select>
                                    </Col>
                                    {this.state.apidata?.data?.length > 0 && 
                                    <Col md={6}>
                                        <Form.Label>
                                            value
                                        </Form.Label>
                                        {/* <Form.Select
            value={this.state.value} // Bind to the selectedDashboardId
            onChange={(e) => {
              this.setState({value:e.target.value})
            }}
            
        >
          <option value="">Select </option>
          {this.state.apidata?.data?.map((group, index) => 
    group.data.map((item, idx) => (
      <option key={item.id} value={item.value}>
        {item.value}
      </option>
    ))
  )}
          
        </Form.Select> */}

<Form.Select
  value={this.state.value} // Bind to the selected value
  onChange={(e) => {
    // const [id, value] = e.target.value.split('|'); // Extract id and value
    const selectedOption = e.target.options[e.target.selectedIndex];
    const fieldId = selectedOption.getAttribute('data-field-id');
    const value = e.target.value;
    this.setState({ selectedId: fieldId, value: value }); // Update state with both id and value
  }}
>
  <option value="">Select</option>
  {this.state.apidata?.data?.map((group, index) =>
    group.data.map((item, idx) => (
      <option key={item.id} value={item.value} data-field-id={item.field}>
        {item.value}
      </option>
    ))
  )}
</Form.Select>
                                    </Col>}
                                    <Col md={6}>
                                        <Form.Label>
                                            Show Form
                                        </Form.Label>
                                        <Form.Select
            value={this.state.showform} // Bind to the selectedDashboardId
            onChange={(e) => {
              this.setState({showform:e.target.value})
            }}
            
        >
        <option value="">Select</option>
        <option value="Template">Template</option>
        <option value="Form">Form</option>
        <option value="Both">Both</option>

          
        </Form.Select>
                                    </Col>
                            <Col md={6}>
                                <Form.Label>Super User</Form.Label>
                                <Form.Check type="switch" label="Is the user a Super User ?" checked={this.state.is_superuser}
                                    onChange={() => this.setState({ is_superuser: !this.state.is_superuser })} />
                            </Col>
                        </Row>
                        <Form.Group className="mt-3 d-flex justify-content-end" >
                            <Button type='submit' variant="warning" size='sm' >UPDATE</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
        groups: state.groupReducer.groups,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        editUser : (accessToken, requestBody, userId) => dispatch(updateUserProfile(accessToken, requestBody, userId)),
        getCustomDashboard: (accessToken,fun) =>
        dispatch(getCustomDashboard(accessToken,fun)),
        fetchAllGroups: (accesstoken) => dispatch(fetchAllGroups(accesstoken)),
        getValueSelectGroup:(accesstoken,tempid,fun)=> dispatch(getValueSelectGroup(accesstoken,tempid,fun))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserUpdateModal);
