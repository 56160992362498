import { fetchApi } from "../../lib/utils";
import { REQUEST_METHOD, BASE_URL, RESPONSE_TYPE } from "../../lib/constants";

import { LOADING_START, LOADING_END } from "../common/actionTypes";
import { setNotification } from "../common/action";

export function getCustomDashboardById(accessToken,id,fun) {
    return function (dispatch) {
        dispatch({ type: LOADING_START });
        fetchApi(`/forms/custom_dashboard/${id}/details/
        `,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                console.log(successResponse,'successResponse')
                dispatch({ type: LOADING_END })
               fun(successResponse)
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            })
    }
}

export function createCustomDashboard(accessToken, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/forms/custom_dashboard/create/`,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, "Data Added successfully", "success"));
               
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody)
    }
}


export function updateCustomDashboard(accessToken, id, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/forms/custom_dashboard/${id}`,
            accessToken,
            REQUEST_METHOD.PUT,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Custom Dashboard Updated Successfully', 'success'))
             
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody
        )
    }
}