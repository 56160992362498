import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Card, Button, } from 'react-bootstrap';
import { fetchFields, fileUpload, saveFormData, checkUnique, fetchFormEntries } from '../action';
import { getFieldValue, dynamicFormDataChangeHandler, validateForm } from '../../../lib/utils';
import FieldHandler from './fields/FieldHandler';
import { setNotification } from '../../common/action';
import Breadcrumbs from '../../common/components/Breadcrumbs';
import { Link } from 'react-router-dom';

class FormDataEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            statusArr: [],
            validationResponse: false,
            hiddenFields: [],
            deafult_value: [],
        }
    }

    componentDidMount() {
        this.props.getFields(this.props.token.access, this.props.params.id);
        this.props.getFormEntries(this.props.token.access, this.props.params.id);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.fields !== this.props.fields) {
            this.props.fields.map((ele, idx) => {
                if (ele.type === "hiddenField") {
                    this.state.hiddenFields.push(ele);
                    let body = {
                        "deafult_value": '',
                        "field": ele.id
                    }
                    this.state.deafult_value?.push(body);
                }
            })
            this.state.hiddenFields = this.state.hiddenFields.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
        }
        this.state.deafult_value = this.state.deafult_value?.filter((v, i, a) => a.findIndex(v2 => (v2.field === v.field)) === i);
    }

    handleDataChange(fieldId, value) {
        this.setState({ data: dynamicFormDataChangeHandler(this.state.data, fieldId, value) });
    }

    fileUpload(id, data) {
        this.props.fileUpload(this.props.token.access, data.files[0], (data) => this.handleDataChange(id, data.id))
    }

    handleSubmitAction(e) {
        e.preventDefault();
        if (validateForm(this.props.fields, this.state.data)) {
            let requestBody = {
                "data": this.state.data,
                "template": this.props.params.id
            }
            if (this.state.validationResponse === false) {
                this.props.saveData(this.props.token.access, this.props.params.id, requestBody, this.props.navigate, this.props.fields);
            }
            else {
                this.props.setNotification(true, 'duplicate data not allowed', "danger")
            }
        }
    }

    render() {
        return (
            <div className="main-content-container p-4 container-fluid">
                <Breadcrumbs name={this.props} disabledStrings={[this.props.params.id]} />
                <Row>
                    <Col>
                        <Row className='mb-3'>
                            <Col md='9' className='d-flex align-items-center'>
                                <span >Form Templates</span>
                            </Col>
                            <Col md='3' className='d-flex justify-content-end'>
                                <Link to={`/forms/template/${this.props.params.id}/share`}>
                                    <Button className="m-1" variant="success">SHARE</Button>
                                </Link>
                            </Col>
                        </Row>
                        <Card>
                            <Card.Body>
                                <div>
                                    <Row>
                                        {this.props.fields.map((field, index) => {
                                            return field.type !== "OCR_VIEW" && field.initial === "True" ? <FieldHandler
                                                fieldData={field}
                                                index={index}
                                                value={getFieldValue(field, this.state.data, false)}
                                                onBlur={(data) => {
                                                    if (data !== '' && field.check_unique == true) {
                                                        this.props.checkUnique(this.props.token.access, this.props.params.id, field.id, data, (resp) => {
                                                            if (resp.status === true) {
                                                                this.props.setNotification(true, 'Already exists', "danger");
                                                                this.setState({ validationResponse: true })
                                                            }
                                                        });
                                                    }
                                                }}
                                                handleDataChange={(field_id, value) => this.handleDataChange(field_id, value)} /> :
                                                <></>
                                        })}
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Button onClick={(e) => { this.handleSubmitAction(e) }} variant="outline-success" className='mt-4'>SUBMIT</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        fields: state.formsReducer.fields,
        formEntryData: state.formsReducer.formEntryData,
        token: state.usersReducer.token,
        checkUniqueStatus: state.formsReducer.checkUniqueStatus,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkUnique: (accessToken, templateId, fieldId, value, returnedData) => dispatch(checkUnique(accessToken, templateId, fieldId, value, returnedData)),
        getFields: (accessToken, formId) => dispatch(fetchFields(accessToken, formId)),
        saveData: (accessToken, templateId, requestBody, navigate, fields) => dispatch(saveFormData(accessToken, templateId, requestBody, navigate, fields)),
        fileUpload: (accessToken, uri, returnedData) => dispatch(fileUpload(accessToken, uri, returnedData)),
        getFormEntries: (accessToken, templateId) => dispatch(fetchFormEntries(accessToken, templateId)),
        setNotification: (show, text, color) => dispatch(setNotification(show, text, color))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormDataEntry);