import React, { useEffect, useState,useCallback } from "react";
import { connect } from "react-redux";
import { Modal, Button, Form, Col, Row, Table } from "react-bootstrap";
import { getCustomDashboard } from "../users/action";
import { BUTTONS, dashboardIcon } from "../../lib/constants";
import { updateCustomDashboard } from "./action";
import { fetchFields, fetchForms } from "../forms/action";

const CustomDashboardUpdateModal = (props) => {
  const [dashboardName, setDashboardName] = useState("");
  const [data, setData] = useState([]);
  const [fields, setFields] = useState({});
  const [allfield, setallfield] = useState([]);
  const [state, setState] = useState({
    name: '',
    type: '',
    template: '',
    field: '',
    function: '',
    states: '',
    dashboardname: {},
    showUpdateModal: false,
    buttonType: '',
    indivisual: '',
    icon: ''
})

const handleChange = (e, itemId, detailId) => {
  const { name, value } = e.target;

  // Step 1: Update the main data structure
  setData((prevData) => {
    return {
      ...prevData,
      results: prevData.results.map((item) => {
        if (item.id !== itemId) return item;

        // Update details within the specific item
        return {
          ...item,
          details: item.details.map((detail) =>
            detail.id === detailId
              ? { ...detail, [name]: value } // Update the specific field in detail
              : detail
          ),
        };
      }),
    };
  });

  // Step 2: Handle additional field logic via props
  if(name == 'template'){
    props.fieldHandle(value.id, (fieldsResponse) => {
     
      setFields((prevFields) => ({
        ...prevFields,
        [detailId]: fieldsResponse, // Dynamically set fields for the specific detailId
      }));
    });
  }

};



  
  useEffect(() => {
    if (props.token?.access && props.dashboardData?.results) {
      setData(props.dashboardData);

      // Preload fields for templates
      props.dashboardData.results.forEach((item) => {
        item.details.forEach((detail) => {
          if (detail.template?.id) {
            props.fieldHandle(detail.template.id, (fieldsResponse) => {
              
              setFields((prevFields) => ({
                ...prevFields,
                [detail.id]: fieldsResponse,
              }));
            });
          }
        });
      });
    }
  }, [props.token?.access, props.dashboardData]);

  useEffect(() => {
    if (props.token?.access && props.dashboardData?.results) {
      setData(props.dashboardData);
  
      
  
      // Preload fields for templates
      props.dashboardData.results.forEach((item) => {
        item.details.forEach((detail) => {
          if (detail.template?.id) {
            props.fieldHandle(detail.template.id, (fieldsResponse) => {
              setFields((prevFields) => ({
                ...prevFields,
                [detail.id]: fieldsResponse,
              }));
            });
          }
        });
      });
    }
  }, [props.token?.access, props.dashboardData]);
  
  useEffect(() => {
    setDashboardName(
      props.dashboardData?.results?.[0]?.dashboard_name || ""
    );
  }, [props.dashboardData]);
  const handleEditAction = (e,id) => {
    e.preventDefault();
    const requestBody = {
      dashboard: { dashboard_name: dashboardName },
      details: data?.results?.flatMap((item) =>
        item.details.map((detail) => ({
          name: detail.name || "",
          type: detail.type || "Card",
          template: Number(detail.template?.id || 0),
          field: Number(detail.field?.id || 0),
          function: detail.function || "",
          state: Number(detail.state?.id || 0),
          colour:detail.colour,
          icon:detail.icon,
          individual:detail.individual,
        }))
      ),
    };

    if (dashboardName && requestBody.details.length > 0) {
      props.updateCustomDashboard(props.token?.access,id, requestBody);
    } else {
      props.setNotification(true, "Please Add Data in Table.", "danger");
    }
  };
console.log(props.dashboardData)
  return (
    <Modal
      size="lg"
      show={props.showModal}
      onHide={() => props.handleClose()}
      backdrop="static"
      keyboard={false}
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Custom Dashboard</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col md={4} className="p-4">
              <Form.Label>
                Dashboard Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                size="sm"
                type="text"
                placeholder="Enter Dashboard Name"
                required
                value={dashboardName}
                onChange={(e) => setDashboardName(e.target.value)}
              />
            </Col>
            <Col md={12} className="px-4">
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Template</th>
                    <th>Field</th>
                    <th>Function</th>
                    <th>State</th>
                    <th>Color</th>
                    <th>Icon</th>
                    <th>Individual</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.results?.map((item) =>
                    item.details.map((detail) => (
                     
                      <tr key={detail.id}>
                        <td>
                          <Form.Control
                            size="sm"
                            type="text"
                            name="name"
                            placeholder="Enter Name"
                            value={detail.name || ""}
                            onChange={(e) => handleChange(e, item.id, detail.id)}
                          />
                        </td>
                        <td>
                          <Form.Select
                            name="type"
                            value={detail.type || "Card"}
                            onChange={(e) => handleChange(e, item.id, detail.id)}
                          >
                            <option value="Card">Card</option>
                            <option value="Table">Table</option>
                          </Form.Select>
                        </td>
                        <td>
                        <Form.Select
  name="template"
  value={detail.template?.id || ""} // Ensure it matches the `id` of the selected template
  onChange={(e) => {
    const selectedValue = e.target.value; // Get the selected value
    const selectedTemplate = props.formLists?.data?.find(
      (template) => template.id == selectedValue // Find the corresponding template object
    );
    
    handleChange(
      { target: { name: e.target.name, value: selectedTemplate } }, // Pass the selected object
      item.id,
      detail.id
    );
  }}
>
  <option value="">Select</option>
  {props.formLists?.data?.map((template, index) => (
    <option key={index} value={template.id}>
      {template.label}
    </option>
  ))}
</Form.Select>

</td>

                        <td>
                          <Form.Select
                            name="field"
                            value={detail.field.id || ""}
                            onChange={(e) => {
                              const selectedValue = e.target.value; // Get the selected value
                              const selectedTemplate = props.fields?.find(
                                (field) => field.id == selectedValue // Find the corresponding template object
                              );

                              handleChange(
                                { target: { name: e.target.name, value: selectedTemplate } }, // Pass the selected object
                                item.id,
                                detail.id
                              );
                            }}
                          >
                            <option value="">Select</option>
                            {props.fields?.map((field, index) => (
                              <option key={index} value={field.id}>
                                {field.label}
                              </option>
                            ))}
                          </Form.Select>
                        </td>
                        <td>
                          <Form.Select
                            name="function"
                            value={detail.function || ""}
                            onChange={(e) => handleChange(e, item.id, detail.id)}
                          >
                            <option value="">Select</option>
                            <option value="Count">Count</option>
                            <option value="Max">Max</option>
                            <option value="Average">Average</option>
                            <option value="Total">Total</option>
                          </Form.Select>
                        </td>
                        <td>
                          <Form.Select
                            name="state"
                            value={detail.state.id || ""}
                            // onChange={(e) => handleChange(e, item.id, detail.id)}
                            onChange={(e) => {
                              const selectedValue = e.target.value; // Get the selected value
                              const selectedTemplate = props.localWorkflowStateList?.find(
                                (workflow) => workflow.id == selectedValue // Find the corresponding template object
                              );

                              handleChange(
                                { target: { name: e.target.name, value: selectedTemplate } }, // Pass the selected object
                                item.id,
                                detail.id
                              );
                            }}
                          >
                            {props.localWorkflowStateList.map((state, index) => (
                              <option key={index} value={state.id}>
                                {state.label}
                              </option>
                            ))}
                          </Form.Select>
                        </td>
                        <td>
                          <Form.Select
                            name="colour"
                            value={detail.colour || ""}
                            onChange={(e) => handleChange(e, item.id, detail.id)}
                          >
                            <option value="">Select</option>
                            {BUTTONS.map((btn, index) => (
                              <option key={index} value={btn.value}>
                                {btn.label}
                              </option>
                            ))}
                          </Form.Select>
                        </td>
                        <td>
                          <Form.Select
                            name="icon"
                            value={detail.icon || ""}
                            onChange={(e) => handleChange(e, item.id, detail.id)}
                          >
                            <option value="">Select</option>
                            {dashboardIcon.map((icon, index) => (
                              <option key={index} value={icon.value}>
                                {icon.icon}
                              </option>
                            ))}
                          </Form.Select>
                        </td>
                        <td>
                          <Form.Select
                            name="individual"
                            value={detail.individual || ""}
                            onChange={(e) => handleChange(e, item.id, detail.id)}
                          >
                            <option value="">Select</option>
                            <option value="true">True</option>
                            <option value="false">False</option>
                          </Form.Select>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </Col>
            <Col md={12} className="d-flex justify-content-end p-4">
              <Button
                variant="outline-success"
                size="sm"
                onClick={(e) => handleEditAction(e, props.dashboardData?.results?.[0]?.id)}
              >
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  token: state.usersReducer.token,
  formList: state.formsReducer.formList,
    fields: state.formsReducer.fields,

    form: state.formsReducer.form,
    workflowStateList: state.workflowReducer.workflowStateList,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomDashboard: (accessToken, payload) =>
    dispatch(getCustomDashboard(accessToken, payload)),
    updateCustomDashboard:(accessToken,id, requestBody)=> dispatch(updateCustomDashboard(accessToken,id, requestBody)),
    getFields: (accessToken, formId, pageNumber) => dispatch(fetchFields(accessToken, formId, pageNumber)),
    getForm: (accessToken, templateId) => dispatch(fetchForms(accessToken, templateId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomDashboardUpdateModal);
