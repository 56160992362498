import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { fetchAllFormsList, fetchData, fetchFields, updateFormData, updateWorkflowFormData, workflowActionComment } from '../action';
import { getFieldValue, validateForm } from '../../../lib/utils';
import CustomFieldHandler from '../../customforms/customfields/FieldHandler';
import FieldHandler from './fields/FieldHandler';
import { setNotification } from '../../common/action';

class WorkflowActionCommentModal extends Component {
    constructor(props) {
        
        super(props)

        this.state = {
            data: [],
            data_obj: {},
            formDetails: {},
            validationResponse: false,
            my_field_id:'',
            checkUniqueData:''
        }
        this.processedFields = new Set(); 
    }
    componentDidUpdate(prevProps, prevState) {
        
        if (this.props.data !== prevProps.data) {
            this.setState({ data: this.props.data.data });
            this.props.getAllForms(this.props.token.access);
        }
        if (this.props.allFormList !== prevProps.allFormList) {
            this.props.updateLoader(true);
            this.props.allFormList.data && this.props.allFormList.data.length > 0 && this.props.allFormList.data.map((item, index) => {
                if (item.id == this.props.params.template_id) {
                    this.setState({ formDetails: item })
                    this.props.updateLoader(false);
                }
            })
        }
    }
    
    componentDidMount(){
        this.props.getData(this.props.token.access, this.props.templateId);
        console.log(this.props.data,'data field')
    }
    // componentDidMount() {
    //     let user = this.props.workflowaction?.results?.[0]?.fields?.[0]?.user?.name || '';
    //     let default_value = this.props.workflowaction?.results?.[0]?.fields?.[0]?.default || '';
        
    //     if (default_value === "Current Time") {
    //         // Format current date and time as needed (e.g., "YYYY-MM-DD HH:mm:ss")
    //         const currentDateTime = new Date().toLocaleString();
    //         default_value = currentDateTime;
    //     }
    
    //     this.setState({ user, default_value });
    // }
   

    dynamicFormDataChangeHandler(storedData, fieldId, value) {
        let data = storedData;
        let flag = true;
        for (var i = 0; i < data.length; i++) {
            if (data[i].field === fieldId) {
                flag = false;
                data[i].value = value;
                break;
            }
        }
        if (flag) {
            data.push({
                value: value,
                field: fieldId,
            });
        }
        return data;
    }
   
    handleDataChange(fieldId, value) {
        console.log(fieldId, value,'t')
        this.setState((prevState) => ({
                     data: this.dynamicFormDataChangeHandler(prevState.data, fieldId, value),
                 }));
                }
    // handleDataChange(fieldId, value) {
    //     console.log(fieldId, value,'t')
    //     let data = this.state.data
    //     let flag = true;
    //     for (var i = 0; i < data.length; i++) {
    //         if (data[i].field === fieldId) {
    //             flag = false;
    //             data[i].value = value;
    //             break;
    //         }
    //     }
    //     if (flag) {
    //         data.push({
    //             value: value,
    //             field: fieldId
    //         })
    //     }
    //     this.setState({ data: data })
    // }
    // handleCreateWorkflowCommentAction(e) {
    //     e.preventDefault();
    //     // let requestBody = {
    //     //     "comment": this.state.comment,
    //     //     "form_data": parseInt(this.props.id),
    //     //     "transition": this.props.transitionId
    //     // }
    //     if (true) {
    //         // this.props.workflowActionComment(this.props.token.access, requestBody);
           
    //         // requestBody["comment"].replace(/\s/g, '').length
    //         if (validateForm(this.props.fields, this.state.data)) {
    //             var my_data = this.props.data.data.map((ele, index) => {
    //                 return {
    //                     "value": ele.value,
    //                     "field": ele.field
    //                 }
    //             });
    //             if (Object.keys(this.state.data_obj).length > 0) {
    //                 my_data = my_data.length > 0 && my_data.map((item, index) => {
    //                     if (Object.keys(this.state.data_obj).includes(String(item.field))) {
    //                         item.value = this.state.data_obj[item.field]
    //                     }
    //                     return item
    //                 })
    //                 var x = my_data.map((ele, i) => {
    //                     return ele.field
    //                 })
    //                 Object.keys(this.state.data_obj).map((item, i) => {
    //                     if (!x.includes(item)) {
    //                         my_data.push({
    //                             "value": this.state.data_obj[item],
    //                             "field": parseInt(item)
    //                         })
    //                     }
    //                 })
    //             }
    //             let requestBody = {
    //                 "data": my_data,
    //                 "is_delete": false,
    //                 "lock_status": this.props.data.lock_status,
    //                 "template": this.props.data.template
    //             }
    
    //             this.props.updateData(this.props.token.access, this.props.data.template, requestBody, this.props.data.id, this.props.navigate);
    //         }
    //     }
        
    //     this.setState({ comment: '' })
    //     this.props.handleClose();
    // }

    handleCreateWorkflowCommentAction(e) {
        e.preventDefault();
        
        const comment = this.state.comment || '';
        let requestBody = {
            "comment": this.state.comment,
            "form_data": parseInt(this.props.id),
            "transition": this.props.transitionId
        }
        if (comment.replace(/\s/g, '').length) {

            this.props.workflowActionComment(this.props.token.access, requestBody);
        // Ensure this.props.fields and this.state.data are defined before calling validateForm
        if (this.state.data) {
           
            if (validateForm(this.props.fields, this.state.data)) {
                let my_data = this.state.data?.map((ele) => ({
                    "value": ele.value,
                    "field": ele.field
                })) || [];
    
                if (Object.keys(this.state.data_obj).length > 0 || Object.keys(this.state.data_obj).length == "undefined") {
                    console.log(this.state.data_obj)
                    my_data = my_data.map((item) => {
                        if (Object.keys(this.state.data_obj).includes(String(item.field))) {
                            item.value = this.state.data_obj[item.field];
                        }
                        return item;
                    });
    
                    const x = my_data.map((ele) => ele.field);
                    Object.keys(this.state.data_obj).forEach((item) => {
                        if (!x.includes(parseInt(item))) {
                            my_data.push({
                                "value": this.state.data_obj[item],
                                "field": parseInt(item)
                            });
                        }
                    });
                }
    
                const requestBody = {
                    "data": my_data,
                    "is_delete": false,
                    "lock_status": this.props.data?.lock_status,
                    "template": this.props.data?.template
                };
               
    console.log(requestBody,'requestBody123....')
                this.props.updateData(this.props.token.access,  requestBody, this.props.data.id);
            }
        }

        this.setState({ comment: '' });
        this.props.handleClose();
        }else{
            this.props.setNotification(true, 'Enter Your Comment', "danger")
        }
       
    }
   
    render() {
       console.log(this.state.data,'daat1')
       console.log(this.props.data,'props daat1')
        return (
            <div>
                <Modal show={this.props.showModal} onHide={() => this.props.handleClose()} backdrop="static" keyboard={false} centered={true} size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title >Comment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <Form onSubmit={(e) => this.handleCreateWorkflowCommentAction(e)} className='font-medium'>
                        <Row
                                            // md={field.style === '' ? 12 : field.style}
                                            // style={{ display: "flex",  marginBottom: 25 }}
                                            // key={`${resultIndex}-${fieldIndex}`}
                                        >
                            {this.props.workflowaction?.results?.map((result, resultIndex) =>
result.label === this.props.label && 
                                result.fields?.map((field, fieldIndex) =>{
                                    console.log(field,'field')
                                    if (field.default === 'Current User' && !this.processedFields.has(field.id)) {
                                        this.processedFields.add(field.id);
                                        this.handleDataChange(field.id, this.props.profileData.name);
                                      }
                                
                                       // Check for "Current Date" default value
                                                                if (
                                                                    field.default === 'Current Date' &&
                                                                    !this.processedFields.has(field.id)
                                                                ) {
                                                                    this.processedFields.add(field.id);
                                                                    const todayDate = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
                                                                    this.handleDataChange(field.id, todayDate);
                                                                }
                                
                                                                // Check for "Date Time" default value
                                                                if (
                                                                    field.default === 'Date Time' &&
                                                                    !this.processedFields.has(field.id)
                                                                ) {
                                                                    this.processedFields.add(field.id);
                                                                    const todayDateTime = new Date().toISOString(); // Get current date and time in ISO format
                                                                    this.handleDataChange(field.id, todayDateTime);
                                                                }
                                                                return field.visible ? (
                                                                    <FieldHandler
                                                                      key={`${resultIndex}-${fieldIndex}`}
                                                                      fieldData={field}
                                                                      index={fieldIndex}
                                                                      value={getFieldValue(
                                                                        field,
                                                                        this.state.data,
                                                                        true,
                                                                        null,
                                                                        2,
                                                                        this.props.profileData.name
                                                                      )}
                                                                    //   handleDataChange={(id, value) => this.handleDataChange(id, value)}
                                                                    handleDataChange={(field_id, value) => {
                                                                        this.handleDataChange(field_id, value);
                                                                        this.setState({
                                                                            my_field_id: field_id,
                                                                            checkUniqueData: value,
                                                                        });
                                                                    }}
                                                                      onBlur={() => {}}
                                                                    />
                                                                  ) : null;                                

                                }
                               
                                    // field.visible === true ? (
                                        
                                    //         <FieldHandler
                                    //              fieldData={field}
                                    //              index={fieldIndex}
                                                
                                    //             value={getFieldValue(field, this.state.data,true,null,2,this.props.profileData.name)}
                                    //              handleDataChange={(id, value) => this.handleDataChange(id, value)}
                                    //              onBlur={() => { }}
                                                 
                                    //         />
                                       
                                    // ) : null
                                )
                            )}
 </Row>

 
                            <div className="form-group">
                                <label for="exampleFormControlTextarea1">Add Comment</label>
                                <textarea className="form-control" onChange={event => { this.setState({ comment: event.target.value }); }}
                                    value={this.state.comment} id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                            <Form.Group className="mt-3 d-flex justify-content-end" >
                                <Button type='submit' variant="warning" size='sm' >ADD COMMENT</Button>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
        workflowaction: state.formsReducer.workflowAction,
        data: state.formsReducer.data,
        fields: state.formsReducer.fields,
        profileData: state.usersReducer.profileData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        workflowActionComment: (accessToken, requestBody) => dispatch(workflowActionComment(accessToken, requestBody)),
        getFields: (accessToken, formId) => dispatch(fetchFields(accessToken, formId)),
        getData: (accessToken, templateId) => dispatch(fetchData(accessToken, templateId)),
        // getWorkflowAction: (accessToken, formId) => dispatch(getWorkflowAction(accessToken, formId)),
        updateData: (accessToken, requestBody, id) => dispatch(updateWorkflowFormData(accessToken, requestBody, id)),
        getAllForms: (accessToken) => dispatch(fetchAllFormsList(accessToken)),
        setNotification: (show, text, color) => dispatch(setNotification(show, text, color)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowActionCommentModal);