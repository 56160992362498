import { FETCH_FORMS_LIST, FETCH_PROJECT_FORM_DATA, HIDDEN_FIELD_VALUE, FETCH_FORM_MEMBERS, FETCH_MEMBERS, FETCH_FORM_FIELDS, FETCH_FORM_ENTRY_DATA, FETCH_COMMENTS, FETCH_FORM_DATA, FETCH_FORM, FETCH_DATA, FETCH_SHARED_DATA, FETCH_SHARED_FORM_FIELDS, FETCH_SHARED_FORM_DATA, FETCH_FORM_WORKFLOW_LIST, FETCH_WORKFLOW_TEMPLATE, WORKFLOW_ACTION, FETCH_ALL_FORMS_LIST, FETCH_USER_LEVEL, FETCH_UNIQUE_STATUS, WORKFLOW_ACTION_LOG, FETCH_ALL_DASHBOARD_LIST, CUSTOM_FORM_LIST, CURRENT_CUSTOM_FORM_DETAILS,FETCH_ALL_TEMPLATE_FORM,FETCH_ALL_CATEGORY,FETCH_TEMPLATE_ALL_FIELD, FETCH_MENU_LIST, FETCH_TEMPLATE_DATA } from './actionTypes';
import { DEFAULT_DATA } from '../../lib/constants';

const initialState = {
    formList: { data: [] },
    fields: [],
    comments: DEFAULT_DATA,
    formData: DEFAULT_DATA,
    form: {},
    data: {},
    sharedData: DEFAULT_DATA,
    formEntryData: {},
    sharedFormFields: DEFAULT_DATA,
    sharedFormData: DEFAULT_DATA,
    projectFormList: DEFAULT_DATA,
    formMembers: [],
    members: DEFAULT_DATA,
    workflowtemplatelist: DEFAULT_DATA,
    checkUniqueStatus: false,
    allFormList: {},
    userLevel: [],
    workflowAction: DEFAULT_DATA,
    workflowActionLog: DEFAULT_DATA,
    hiddenfieldValue: {},
    dashboarddetails: {},
    customFromList: {},
    currentCustomFormDetails: {},
    templateform:{},
    allCategory:{},
    templateAllField:{},
    allmenulist:{},
    templatedata:{},
};

const formsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FORMS_LIST:
            return {
                ...state,
                formList: action.payload
            };

        case CUSTOM_FORM_LIST:
            return {
                ...state,
                customFromList: action.payload
            };
        case CURRENT_CUSTOM_FORM_DETAILS:
            return {
                ...state,
                currentCustomFormDetails: action.payload
            };

        case FETCH_SHARED_DATA:
            return {
                ...state,
                sharedData: action.payload
            };
        case FETCH_FORM_FIELDS:
            return {
                ...state,
                fields: action.payload
            }
        case FETCH_FORM_DATA:
            return {
                ...state,
                formData: action.payload
            }
        case FETCH_FORM:
            return {
                ...state,
                form: action.payload
            }
        case FETCH_DATA:
            return {
                ...state,
                data: action.payload
            }
        case FETCH_SHARED_FORM_FIELDS:
            return {
                ...state,
                sharedFormFields: action.payload
            }
        case FETCH_SHARED_FORM_DATA:
            return {
                ...state,
                sharedFormData: action.payload
            }
        case FETCH_FORM_ENTRY_DATA:
            return {
                ...state,
                formEntryData: action.payload
            }
        case FETCH_COMMENTS:
            return {
                ...state,
                comments: action.payload
            }
        case FETCH_PROJECT_FORM_DATA:
            return {
                ...state,
                projectFormList: action.payload
            }
        case FETCH_FORM_MEMBERS:
            return {
                ...state,
                formMembers: action.payload
            }
        case FETCH_MEMBERS:
            return {
                ...state,
                members: action.payload
            }
        case FETCH_WORKFLOW_TEMPLATE:
            return {
                ...state,
                workflowtemplatelist: action.payload
            }
        case FETCH_UNIQUE_STATUS:
            return {
                ...state,
                checkUniqueStatus: action.payload
            }
        case FETCH_ALL_FORMS_LIST:
            return {
                ...state,
                allFormList: action.payload
            }
        case FETCH_USER_LEVEL:
            return {
                ...state,
                userLevel: action.payload
            }
        case WORKFLOW_ACTION:
            return {
                ...state,
                workflowAction: action.payload
            };
        case WORKFLOW_ACTION_LOG:
            return {
                ...state,
                workflowActionLog: action.payload
            }
        case HIDDEN_FIELD_VALUE:
            return {
                ...state,
                hiddenfieldValue: action.payload
            }
        case FETCH_ALL_DASHBOARD_LIST:
            return {
                ...state,
                dashboarddetails: action.payload
            }
            case FETCH_ALL_TEMPLATE_FORM:
                return {
                    ...state,
                    templateform: action.payload
                }
                case FETCH_ALL_CATEGORY:
                    return{
                        ...state, 
                        allCategory: action.payload
                    }
                    case FETCH_TEMPLATE_ALL_FIELD:
                        return{
                            ...state, 
                            templateAllField: action.payload
                        }
                        case FETCH_MENU_LIST:
                            return{
                                ...state, 
                                allmenulist: action.payload
                            }
                            case FETCH_TEMPLATE_DATA:
                            return{
                                ...state, 
                                templatedata: action.payload
                            }
        default:
            return state;
    }
};

export default formsReducer;
