import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Card, Button, Stack } from 'react-bootstrap';
import { FIELDS, FIELD_VALUE_MAPPING, VALIDATORS, UNOIQUE_TYPE } from '../../../lib/constants';
import { formateDate, formateBooleanData, checkDataSourceFieldType, checkHiddenField, getHiddenDeleteField } from '../../../lib/utils';
import { fetchFields, createFormField, fetchFormsList, deleteField, fetchAllFormsList, fetchForms } from '../action';
import DataTable from '../../common/components/DataTable';
import FormFieldUpdateModal from './FormFieldUpdateModal';
import DeleteModal from '../../common/components/DeleteModal';
import { setNotification } from '../../common/action';


class Fields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDeleteModal: false,
            showUpdateModal: false,
            selectedData: {},
            label: '',
            type: '',
            mandatory: false,
            mainView: true,
            sequence: '',
            placeholder: '',
            tooltip: '',
            dataSource: 'string',
            validationType: null,
            validationMessage: null,
            validationExpression: null,
            check_unique: false,
            unique_type: 1,
            auto_sum: false,
            defaultValue: '',
            OCR_View: false,
            send_alert:false,
            lower_value:'',
            higher_value:'',
            initial:"True"
        }
    }

    componentDidMount() {
        this.props.getFields(this.props.token.access, this.props.params.id);
        this.props.getFormList(this.props.token.access);
        this.props.getAllForms(this.props.token.access);
        this.props.getForm(this.props.token.access, this.props.params.id);
    }

    deleteAction(event) {
        this.props.deleteField(this.props.token.access, event.id, this.props.params.id, event.ocr_view, () => { this.deleteAction(getHiddenDeleteField(event.id, this.props.fields)) });
    }

    handleCreateAction(event) {
        event.preventDefault();
        let ValidationFlag = true;
        let requestBody = {
            "validation": {
                "type": this.state.validationType,
                "expression": this.state.validationExpression,
                "error_message": this.state.validationMessage
            },
            "label": this.state.label,
            "type": this.state.type,
            "mandatory": this.state.mandatory,
            "sequence": this.state.sequence,
            "template": this.props.params.id,
            "display_in_main_view": this.state.mainView,
            "placeholder": this.state.placeholder,
            "tooltip": this.state.tooltip,
            "master_data_code": this.state.dataSource,
            "check_unique": this.state.check_unique,
            "unique_type": this.state.unique_type,
            "auto_sum": this.state.auto_sum,
            "deafult_value": this.state.defaultValue,
            "ocr_view": this.state.OCR_View,
            "send_alert":this.state.send_alert,
            "higher_value":this.state.higher_value,
            "lower_value":this.state.lower_value,
            "initial":this.state.initial
        }

        if (checkDataSourceFieldType(this.state.type)) {
            if (this.state.dataSource.length >= 1) {
                requestBody[`master_data_code`] = this.state.dataSource;
            }
            else {
                ValidationFlag = false;
                this.props.setNotification(true, "Please select a Data Source", "success")
            }
        }

        if (ValidationFlag) {
            this.props.addFields(this.props.token.access, this.props.params.id, requestBody);
            this.setState({
                label: '',
                type: '',
                mandatory: false,
                mainView: true,
                sequence: '',
                placeholder: '',
                tooltip: '',
                dataSource: 'string',
                validationMessage: null,
                validationExpression: null,
                validationType: null,
                check_unique: false,
                unique_type: 1,
                auto_sum: false,
                defaultValue: '',
                lower_value: '',   // Clear lower_value
        higher_value: '',  // Clear higher_value
            initial:"True"
            })
        }
    }

    render() {
        return (
            <div className="main-content-container p-4 container-fluid">
                <Row>
                    <Col>
                        <h4>{this.props.form.label}</h4>
                        <p>{this.props.form.description}</p>
                        <Card>
                            <Card.Body>
                                <Form onSubmit={(e) => { this.handleCreateAction(e) }}>
                                    <Row>
                                        <Col md={3} className='mb-3'>
                                            <Form.Label>Field Name <span className='text-danger'>*</span></Form.Label>
                                            <Form.Control size='sm' type="text" placeholder="Enter Field Name" required value={this.state.label}
                                                onChange={event => { this.setState({ label: event.target.value }); }} />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Placeholder</Form.Label>
                                            <Form.Control size='sm' type="text" placeholder="Enter Placeholder" value={this.state.placeholder}
                                                onChange={event => { this.setState({ placeholder: event.target.value }); }} />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>ToolTip</Form.Label>
                                            <Form.Control size='sm' type="text" placeholder="Enter ToolTip" value={this.state.tooltip}
                                                onChange={event => { this.setState({ tooltip: event.target.value }); }} />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Type <span className='text-danger'>*</span></Form.Label>
                                            <Form.Select size='sm' value={this.state.type} onChange={(event) => this.setState({ type: event.target.value })} required>
                                                <option>-Select Field Type-</option>
                                                {FIELDS.map((row, index) => {
                                                    if (row.label !== 'Image' && row.label !== 'Signature' && row.label !== 'Table') {
                                                        return (
                                                            <option value={row.value} key={index}>{row.label}</option>
                                                        )
                                                    }
                                                })}
                                            </Form.Select>
                                        </Col>
                                        {checkDataSourceFieldType(this.state.type) ?
                                            <Col md={3}>
                                                <Form.Label>Data Source Form <span className='text-danger'>*</span></Form.Label>
                                                <Form.Select size="sm" defaultValue={this.state.dataSource} onChange={(event) => this.setState({ dataSource: event.target.value })} required >
                                                    <option value={'string'}>-Select Data Source-</option>
                                                    {this.props.allFormList && this.props.allFormList.data && this.props.allFormList.data.length > 0 && this.props.allFormList.data.map((row, index) => {
                                                        return (
                                                            <option value={row.id} key={index}>{row.label}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </Col> : null}
                                        {checkHiddenField(this.state.type) ?
                                            <Col md={3}>
                                                <Form.Label>Default Value <span className='text-danger'>*</span></Form.Label>
                                                <Form.Control size='sm' type="text" placeholder="Set default value for hidden field" required value={this.state.defaultValue}
                                                    onChange={event => { this.setState({ defaultValue: event.target.value }); }} />
                                            </Col> : null}
                                          
                             <Col md={3}>
                                        <Form.Label>Initial<span className='text-danger'>*</span></Form.Label>
                                
                                <div>
                                    <Form.Select value={this.state.initial}  onChange={(event) => this.setState({ initial: event.target.value })}>
                                        <option value="True" selected>True</option>
                                        <option value="False">False</option>
                                    </Form.Select>
                                </div>
                                </Col>
                                        <Col md={3}>
                                            <Form.Label>Show Alert</Form.Label>
                                            <Form.Check type="switch" label="" checked={this.state.send_alert}
                                                onChange={() => this.setState({ send_alert: !this.state.send_alert })} />
                                        </Col>
                                        {this.state.send_alert && 
                                            <>
                                            <Col md={3} > <Form.Label>Lower Value</Form.Label>
                                 <Form.Control type="text" placeholder={'Enter Lower Value'}
                                     value={this.state.lower_value}
                                     onChange={(event) => {
                                         this.setState({ lower_value: event.target.value })
                                     }} />
                            </Col>
                         <Col md={3} >
                             <Form.Label>Higher Value</Form.Label>
                                 <Form.Control type="text" placeholder={'Enter Higher Value'}
                                     value={this.state.higher_value}
                                     onChange={(event) => {
                                         this.setState({ higher_value: event.target.value })
                                     }} />
                             </Col>
                             </>
    }
                                        <Col md={3}>
                                            <Form.Label>Mandatory </Form.Label>
                                            <Form.Check checked={this.state.mandatory} type="switch" label="Is this field mandatory?"
                                                onChange={() => this.setState({ mandatory: !this.state.mandatory })} />
                                        </Col>
                                        
                                        <Col md={3}>
                                            <Form.Label>Display in Main View </Form.Label>
                                            <Form.Check type="switch" label="Display in Main View?" checked={this.state.mainView}
                                                onChange={() => this.setState({ mainView: !this.state.mainView })} />
                                        </Col>
                                        {checkDataSourceFieldType(this.state.type) || checkHiddenField(this.state.type) ? null :
                                            <>
                                                <Col md={3}>
                                                    <Form.Label>Validators</Form.Label>
                                                    <Form.Select size='sm' value={this.state.validationType} onChange={(event) => this.setState({ validationType: event.target.value })}>
                                                        <option value={null}>-Select Validator Type-</option>
                                                        {VALIDATORS.map((row, index) => {
                                                            return (
                                                                <option value={row.value} key={index}>{row.label}</option>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Label>Validation Error Message {this.state.validationType == null ? null : <span className='text-danger'>*</span>}</Form.Label>
                                                    <Form.Control size='sm' type="text" placeholder="Error Message"
                                                        required={this.state.validationType !== null}
                                                        value={this.state.validationMessage}
                                                        onChange={event => { this.setState({ validationMessage: event.target.value }); this.setState({ validationExpression: 'na' }) }} />
                                                </Col>
                                            </>}
                                    </Row>
                                    <br />
                                    <Row >
                                        <Col md={3}>
                                            <Form.Label>Sequence<span className='text-danger'>*</span></Form.Label>
                                            <Form.Control size='sm' min="1" type="number" placeholder="Sequence" required value={this.state.sequence}
                                                onChange={event => { this.setState({ sequence: event.target.value }); }} />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Check Unique</Form.Label>
                                            <Form.Check type="switch" label="Is the field value unique?" checked={this.state.check_unique}
                                                onChange={() => this.setState({ check_unique: !this.state.check_unique })} />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Unique Type</Form.Label>
                                            <Form.Select size='sm' value={this.state.unique_type} onChange={(event) => this.setState({ unique_type: event.target.value })} >
                                                {UNOIQUE_TYPE.map((row, index) => {
                                                    return (
                                                        <option value={row.value} key={index}>{row.label}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Auto Sum</Form.Label>
                                            <Form.Check type="switch" label="Calculate Sum?" checked={this.state.auto_sum}
                                                onChange={() => this.setState({ auto_sum: !this.state.auto_sum })} />
                                        </Col>
                                        <br />
                                        {(this.state.type == "file" || this.state.type == "image") &&
                                            <Col md={3}>
                                                <Form.Label>Need OCR</Form.Label>
                                                <Form.Check type="switch" label="Need OCR" checked={this.state.OCR_View}
                                                    onChange={() => this.setState({ OCR_View: !this.state.OCR_View })} />
                                            </Col>
                                        }

                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Button type='submit' variant="outline-success" size='sm' className='mt-4'>CREATE</Button>
                                        </Col>
                                    </Row>
                                </Form>
                                <div className='mt-5 font-medium' >
                                    <DataTable
                                        totalItemsCount={this.props.fields.count}
                                        onPageChange={(pageNumber) => this.props.getFields(this.props.token.access, this.props.params.id, pageNumber)}
                                        headers={[`Sequence`, `Field`, `Type`, `Placeholder`, `ToolTip`, `Validation`, `Main View`, `Last Modified`, `Action`]}
                                        body={this.props.fields.map((row, index) => {
                                            return row.type !== "OCR_VIEW" ? [
                                                row.sequence,
                                                <>
                                                    {row.label} {row.mandatory ? <span className='text-danger'>*</span> : null}
                                                </>,
                                                row.type,
                                                row.placeholder,
                                                row.tooltip,
                                                row.validation === null ? null : <>
                                                    <span>Type: {row.validation.type}</span><br />
                                                    <span>Message: {row.validation.error_message}</span></>,
                                                formateBooleanData(row.display_in_main_view),
                                                formateDate(row.date_updated),
                                                <Stack direction="horizontal" gap={2}>
                                                    <Button title='Edit' variant="info" size='sm' onClick={() => this.setState({ showUpdateModal: true, selectedData: row })}><i class="fa fa-pencil-square-o" aria-hidden="true" style={{ fontSize: "15px", color: "white",padding:"2px 3px"}}></i></Button>
                                                    <Button title='Delete' variant="danger" size='sm' onClick={() => this.setState({ showDeleteModal: true, selectedData: row })}><i class="fa fa-trash-o" aria-hidden="true" style={{ fontSize: "15px", color: "white",padding:"2px 3px"}}></i></Button>
                                                </Stack>
                                            ] : []
                                        })
                                        } />
                                </div>
                                <DeleteModal modalText={"Delete Field ?"} showModal={this.state.showDeleteModal} handleClose={() => this.setState({ showDeleteModal: false })}
                                    deleteAction={(status) => { if (status !== undefined && status == true) { this.deleteAction(this.state.selectedData) } }} selectedData={this.state.selectedData}
                                />
                                <FormFieldUpdateModal showModal={this.state.showUpdateModal} handleClose={() => this.setState({ showUpdateModal: false })} selectedData={this.state.selectedData} formId={this.props.params.id} fieldsData={this.props.fields} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        fields: state.formsReducer.fields,
        formList: state.formsReducer.formList,
        token: state.usersReducer.token,
        allFormList: state.formsReducer.allFormList,
        form: state.formsReducer.form,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAllForms: (accessToken) => dispatch(fetchAllFormsList(accessToken)),
        deleteField: (accessToken, fieldId, formId, OCR_VIEW, afterDelet) => dispatch(deleteField(accessToken, fieldId, formId, OCR_VIEW, afterDelet)),
        getFormList: (accessToken) => dispatch(fetchFormsList(accessToken)),
        getForm: (accessToken, templateId) => dispatch(fetchForms(accessToken, templateId)),
        getFields: (accessToken, formId, pageNumber) => dispatch(fetchFields(accessToken, formId, pageNumber)),
        addFields: (accessToken, templateId, requestBody) => dispatch(createFormField(accessToken, templateId, requestBody)),
        setNotification: (show, text, color) => dispatch(setNotification(show, text, color))

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Fields);
