import { REQUEST_METHOD } from "../../lib/constants"
import { fetchApi } from "../../lib/utils"
import { LOADING_START, LOADING_END, SET_NOTIFICATION, FETCH_DASHBOARD, USER_LIST, USER_BILLINH_LIST } from "./actionTypes"
import Swal from 'sweetalert2'

export function updateLoader(status) {
    return function (dispatch) {
        if (status) {
            dispatch({ type: LOADING_START })
        } else {
            dispatch({ type: LOADING_END })
        }
    }
}

export function setNotification(show, text, color) {
    return function (dispatch) {
        dispatch({ type: SET_NOTIFICATION, payload: { show: show, text: text, color: color } })
    }
}

export function copyTextToClipboard(copyText, message = 'Coppied') {
    return function (dispatch) {
        navigator.clipboard.writeText(copyText);
        dispatch(setNotification(true, message, 'success'));
    }
}

export function fetchDashboard(accessToken) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/dashboard/`,
            accessToken, REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: FETCH_DASHBOARD, payload: successResponse })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            })
    }
}

export function fetchUserList(accessToken, pageNo = 1, search = '') {
    var my_search = ''
    if (search !== '') {
        my_search = `search=${search}&`
    }
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/user/all/?${my_search}page=${pageNo}`,
            accessToken, REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: USER_LIST, payload: successResponse })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            })
    }
}
export function fetchContact(token, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/forms/feature/share/${token}/store/`,
            null,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: successResponse.message,
                    confirmButtonColor: '#11134c',
                  })
            },

            (failureResponse) => {
                dispatch({ type: LOADING_END })
            },
            requestBody
        )
    }
}



export function fetchPartner(token, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/forms/feature/share/${token}/store/`,
            null,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: successResponse.message,
                    confirmButtonColor: '#11134c',
                  })
            },

            (failureResponse) => {
                dispatch({ type: LOADING_END })
            },
            requestBody
        )
    }
}



export function fetcInternal(accessToken, pageNo = 1, search = '') {
    var my_search = ''
    if (search !== '') {
        my_search = `search=${search}&`
    }
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/user/billing-users/all/?${my_search}page=${pageNo}`,
            accessToken, REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: USER_BILLINH_LIST, payload: successResponse })
                
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse, 'danger'))
                dispatch({ type: LOADING_END })
            })
    }
}



export function fetchAdminDashboard(accessToken,fun) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/dashboard/admin/`,
            accessToken, REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                fun(successResponse)
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            })
    }
}