import { fetchApi, getHiddenDeleteField, returnActiveCustomForm } from "../../lib/utils";
import { REQUEST_METHOD, BASE_URL, RESPONSE_TYPE } from "../../lib/constants";
import { FETCH_FORMS_LIST, FETCH_FORM_MEMBERS, HIDDEN_FIELD_VALUE, FETCH_MEMBERS, FETCH_PROJECT_FORM_DATA, FETCH_FORM_FIELDS, FETCH_COMMENTS, FETCH_FORM_ENTRY_DATA, FETCH_FORM_DATA, FETCH_FORM, FETCH_DATA, FETCH_SHARED_DATA, FETCH_SHARED_FORM_FIELDS, FETCH_SHARED_FORM_DATA, FETCH_FORM_WORKFLOW_LIST, FETCH_WORKFLOW_TEMPLATE, WORKFLOW_ACTION, FETCH_USER_LEVEL, FETCH_UNIQUE_STATUS, FETCH_ALL_FORMS_LIST, WORKFLOW_ACTION_LOG, FETCH_ALL_DASHBOARD_LIST, CUSTOM_FORM_LIST, CURRENT_CUSTOM_FORM_DETAILS, FETCH_ALL_TEMPLATE_FORM, FETCH_ALL_CATEGORY, FETCH_TEMPLATE_ALL_FIELD, FETCH_MENU_LIST, FETCH_TEMPLATE_DATA } from "./actionTypes";
import { LOADING_START, LOADING_END } from "../common/actionTypes";
import { setNotification } from "../common/action";
import { fetchWorkflowList } from "../workflows/action";
import { fetchProjectForms } from "../project/action";
import Toast from 'react-bootstrap/Toast';
import FileTypeFieldHandler from "./components/fields/FileTypeFieldHandler";

export function fetchAllFormsList(accessToken, pageNumber = 1) {
  return function (dispatch) {
    dispatch({ type: LOADING_START });
    fetchApi(`/forms/?page=${pageNumber}`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch({ type: FETCH_ALL_FORMS_LIST, payload: successResponse })
      },
      (failureResponse) => {
        console.log(failureResponse)
        dispatch(setNotification(true, failureResponse, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}
export function fetchDashboardDetails(accessToken) {
  return function (dispatch) {
    dispatch({ type: LOADING_START });
    fetchApi(`/billings/alldata/`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch({ type: FETCH_ALL_DASHBOARD_LIST, payload: successResponse })
      },
      (failureResponse) => {
        // dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}
export function fetchFormTemplate(accessToken) {
  return function (dispatch) {
    dispatch({ type: LOADING_START });
    fetchApi(`/common/custom_template/`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch({ type: FETCH_ALL_TEMPLATE_FORM, payload: successResponse })
      },
      (failureResponse) => {
        // dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}
// AllCategory
export function fetchFormTemplateAllCategory(accessToken) {
  return function (dispatch) {
    dispatch({ type: LOADING_START });
    fetchApi(`/common/custom_template/all_category/`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        console.log(successResponse, 'successResponse')
        dispatch({ type: LOADING_END })
        dispatch({ type: FETCH_ALL_CATEGORY, payload: successResponse })
      },
      (failureResponse) => {
        // dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}
// TemplateByCategory
export function fetchFormTemplateByCategory(accessToken, category, fun) {
  return function (dispatch) {
    dispatch({ type: LOADING_START });
    fetchApi(`/common/custom_template/category/${category}`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        console.log(successResponse, 'successResponse')
        dispatch({ type: LOADING_END })
        // dispatch({ type: FETCH_ALL_CATEGORY, payload: successResponse })
        fun(successResponse)
      },
      (failureResponse) => {
        // dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}

// ActiveTemplateForm
export function fetchActiveTemplateForm(accessToken, form_id, fun) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/common/custom_template/${form_id}/`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        fun(successResponse.results)
        dispatch(fetchTemplateAllFields(accessToken, successResponse?.results?.form_id))

        console.log(successResponse, 'successResponse fetchActiveTemplateForm')
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}

// get template all field
export function fetchTemplateAllFields(accessToken, formId) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/common/create_form/${formId}/`,
      accessToken,
      REQUEST_METHOD.POST,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch({ type: FETCH_TEMPLATE_ALL_FIELD, payload: successResponse })
        dispatch(fetchActiveCustomForm(accessToken, successResponse.form_id))
        // callback(successResponse)
        console.log(successResponse, 'successResponse fetchTemplateAllFields')
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}

// get multisearchdata preview form
export function fetchMultiSearchData(accessToken, template_id, queryParams, response_id, fun) {
  return function (dispatch) {
    dispatch({ type: LOADING_START });

    // Construct the query with quotes around it
    // const query = `'${queryKey}:${queryValue}'`;

    // const queryString = queryParams.map(({ key, value }) => `${key}:${value}`).join(',');
    //   console.log(queryString, 'queryString');
    // const queryString = queryParams.map(({ key, value }) => `${key}:${value}`).join(',');

    // Wrap the entire queryString in single quotes
    // const wrappedQueryString = `'${queryString}'`;
    // console.log(wrappedQueryString, 'wrappedQueryString');
    console.log(queryParams, response_id, template_id)
    const url = `/forms/template/${template_id}/multisearchdata/?query=${encodeURIComponent(queryParams)}&value_id=${response_id}`;

    fetchApi(
      url,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: LOADING_END });
        fun(successResponse);
        console.log(successResponse, 'fetchMultiSearchData');
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'));
        dispatch({ type: LOADING_END });
      }
    );
  };
}

export function fetchFormsList(accessToken, searchQuery = '', pageNumber = 1) {
  return function (dispatch) {
    dispatch({ type: LOADING_START });
    fetchApi(`/forms/?page=${pageNumber}${searchQuery}`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch({ type: FETCH_FORMS_LIST, payload: successResponse })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}

export function fetchProjectFormsList(accessToken, id) {
  return function (dispatch) {
    fetchApi(`/project/${id}/template/`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch({ type: FETCH_PROJECT_FORM_DATA, payload: successResponse })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}


export function fetchForms(accessToken, templateId) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/${templateId}/`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: FETCH_FORM, payload: successResponse })
        dispatch({ type: LOADING_END })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}

export const getCustomForm = (accessToken, getActiveStatus = false) => {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/common/custom-form/`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: CUSTOM_FORM_LIST, payload: successResponse })

        if (successResponse.data.length > 0 && getActiveStatus == true) {
          dispatch({ type: CURRENT_CUSTOM_FORM_DETAILS, payload: returnActiveCustomForm(successResponse.data) })
          dispatch(fetchFields(accessToken, returnActiveCustomForm(successResponse.data).form_id))
        }
        dispatch({ type: LOADING_END })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}

export function fetchActiveCustomForm(accessToken, form_id) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/common/custom-form/${form_id}/`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch({ type: CURRENT_CUSTOM_FORM_DETAILS, payload: successResponse.results[0] ?? {} })
        dispatch(fetchFields(accessToken, successResponse.results[0].form_id))
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}

export function createCustomForms(accessToken, requestBody) {
  return function (dispatch) {
    fetchApi(`/common/custom-form/`,
      accessToken,
      REQUEST_METHOD.POST,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        if (successResponse.results.custom_template_type == 'C') {
          window.location.reload();
        }
        else {
          dispatch(getCustomForm(accessToken))
        }
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }, requestBody)
  }
}

export function updateCustomForms(accessToken, requestBody) {
  return function (dispatch) {
    fetchApi(`/common/custom-form/`,
      accessToken,
      REQUEST_METHOD.PUT,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch(fetchActiveCustomForm(accessToken, successResponse.results.form_id))
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }, requestBody)
  }
}


export function deleteCustomForm(accessToken, formId) {
  return function (dispatch) {
    dispatch({ type: LOADING_START });
    fetchApi(`/common/custom-form/${formId}/`,
      accessToken,
      REQUEST_METHOD.DELETE,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch(getCustomForm(accessToken));
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }, null, RESPONSE_TYPE.NULL
    )
  }
}



export function createForms(accessToken, requestBody, createdFun) {
  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${accessToken}`);
  var formdata = new FormData()
  formdata.append("label", requestBody.label);
  formdata.append("description", requestBody.description);
  formdata.append("image_header", requestBody.image_header);
  formdata.append("image_footer", requestBody.image_footer);
  formdata.append("custom_template_type", requestBody.custom_template_type);
  formdata.append("type", requestBody.type);
  formdata.append("email_comments", requestBody.email_comments);
  formdata.append("email_formdatas", requestBody.email_formdatas);
  formdata.append("email_workflows", requestBody.email_workflows);
  formdata.append("data_secure", requestBody.data_secure);
  formdata.append("lock_status", requestBody.lock_status);
  if (requestBody.timmer !== 0) {
    formdata.append("timmer", requestBody.timmer);
  }

  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  return function (dispatch) {
    dispatch({ type: LOADING_START });
    fetch(`${BASE_URL}/forms/`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setTimeout(() => {
          if (result.id !== undefined) {
            dispatch(setNotification(true, "Form Created SuccessFully", 'success'));
            dispatch(fetchFormsList(accessToken));
          }
          else {
            dispatch(setNotification(true, String(Object.values(result)[0]), 'danger'));
          }

        }, 0);

        if (requestBody.type == 'C' && result.id !== undefined) {
          dispatch(fetchForms(accessToken, result.id))
          createdFun(result)
        }
        dispatch({ type: LOADING_END });
        dispatch(fetchAllFormsList(accessToken))
      }


      )
      .catch(error => {
        console.log('error', error);
        dispatch({ type: LOADING_END });
      });
  }
}

export function deleteSharedForm(accessToken, shareId, templateId) {
  return function (dispatch) {
    dispatch({ type: LOADING_START });
    fetchApi(`/forms/share/${shareId}/`,
      accessToken,
      REQUEST_METHOD.DELETE,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch(setNotification(true, 'Deleted Successfully', 'danger'));
        dispatch(fetchSharedData(accessToken, templateId));
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }
    )
  }
}

export function deleteForm(accessToken, formId, type = 'N') {
  return function (dispatch) {
    dispatch({ type: LOADING_START });
    fetchApi(`/forms/${formId}/delete/`,
      accessToken,
      REQUEST_METHOD.DELETE,
      (successResponse) => {

        dispatch(setNotification(true, 'Deleted Successfully', 'danger'));
        if (type == 'C') {
          dispatch(deleteCustomForm(accessToken, formId))
        }
        dispatch(fetchFormsList(accessToken));

        dispatch({ type: LOADING_END })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }, null, RESPONSE_TYPE.NULL
    )
  }
}

export function deleteField(accessToken, fieldId, formId, OCR_VIEW, afterDelet) {
  return function (dispatch) {
    dispatch({ type: LOADING_START });
    fetchApi(`/forms/fields/${fieldId}/`,
      accessToken,
      REQUEST_METHOD.DELETE,
      (successResponse) => {
        dispatch(setNotification(true, 'Deleted Successfully', 'danger'));

        dispatch(fetchFields(accessToken, formId));
        if (OCR_VIEW == true) {
          afterDelet()
        }

        dispatch({ type: LOADING_END })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }, null, RESPONSE_TYPE.NULL
    )
  }
}

export function updateForms(accessToken, pk, formdata, updatedData) {
  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${accessToken}`);
  let requestOptions = {
    method: 'PATCH',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  return function (dispatch) {
    dispatch({ type: LOADING_START });
    fetch(`${BASE_URL}/forms/${pk}/`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.type == 'C') {
          var my_updatedData = { ...updatedData, image_header: result.image_header, image_footer: result.image_footer }
          var updated_body = {
            form_id: pk,
            custom_template_type: updatedData.custom_template_type,
            active: true,
            custom_form_data: my_updatedData
          }
          dispatch(updateCustomForms(accessToken, updated_body))
        }
        dispatch(setNotification(true, 'Form Updated Successfully', 'success'))
        dispatch(fetchFormsList(accessToken))
        dispatch({ type: LOADING_END })
      }

      )
      .catch(error => {
        console.log('error', error);
        dispatch({ type: LOADING_END });
      });
  }
}

export function updateFieldForms(accessToken, pk, requestBody, formId, problemSolved) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/fields/${pk}/`,
      accessToken,
      REQUEST_METHOD.PUT,
      (successResponse) => {
        if (requestBody.ocr_view == true) {
          var requestBody2 = {
            "label": `OCR_${successResponse.id}`,
            "ocr_view": false,
            "auto_sum": false,
            "check_unique": false,
            "deafult_value": `OCR_${successResponse.id}`,
            "display_in_main_view": false,
            "mandatory": false,
            "master_data_code": successResponse.id,
            "placeholder": "",
            "sequence": "1",
            "template": successResponse.template,
            "tooltip": "",
            "type": "OCR_VIEW",
            "unique_type": 1,
          }
          dispatch(createFormField(accessToken, formId, requestBody2))
        }
        dispatch({ type: LOADING_END });
        dispatch(setNotification(true, 'Form Field Updated Successfully', 'success'))
        dispatch(fetchAllFormsList(accessToken));
        dispatch(fetchFields(accessToken, formId));
        problemSolved(true)
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END });
      },
      requestBody
    )
  }
}
export function fetchFieldsDropdown(accessToken, formId, fun) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/template/${formId}/fields/`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        fun(successResponse)
        // dispatch({ type: FETCH_FORM_FIELDS, payload: successResponse })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}
export function fetchFields(accessToken, formId) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/template/${formId}/fields/`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch({ type: FETCH_FORM_FIELDS, payload: successResponse })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}

export function createFormField(accessToken, formId, requestBody) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/fields/`,
      accessToken,
      REQUEST_METHOD.POST,
      (successResponse) => {
        dispatch(setNotification(true, 'Form Field Created Successfully', 'success'))
        dispatch(fetchFields(accessToken, formId))
        if (requestBody.ocr_view == true) {
          var requestBody2 = {
            "label": `OCR_${successResponse.id}`,
            "ocr_view": false,
            "auto_sum": false,
            "check_unique": false,
            "deafult_value": `OCR_${successResponse.id}`,
            "display_in_main_view": false,
            "mandatory": false,
            "master_data_code": successResponse.id,
            "placeholder": "",
            "sequence": "1",
            "template": successResponse.template,
            "tooltip": "",
            "type": "OCR_VIEW",
            "unique_type": 1,
          }
          dispatch(createFormField(accessToken, formId, requestBody2))
        }
        dispatch({ type: LOADING_END })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse, 'danger'))
        dispatch({ type: LOADING_END })
      },
      requestBody)
  }
}

export function fetchFormData(accessToken, templateId, pageNumber = 1) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/template/${templateId}/data/?page=${pageNumber}`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: FETCH_FORM_DATA, payload: successResponse })
        dispatch({ type: LOADING_END })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}

export function fetchSharedData(accessToken, templateId, pageNumber = 1) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/template/${templateId}/share/?page=${pageNumber}`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch({ type: FETCH_SHARED_DATA, payload: successResponse })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}


export function postSharedData(accessToken, requestBody, templateId) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/share/`,
      accessToken,
      REQUEST_METHOD.POST,
      (successResponse) => {
        dispatch(fetchSharedData(accessToken, templateId))
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      },
      requestBody)
  }
}

export function ocrView(accessToken, file, returnedText) {

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  var formdata = new FormData();
  formdata.append("file", file);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetch(`${BASE_URL}/fileupload/file/ocr/`, requestOptions)
      .then(response => response.json())
      .then(result => {
        dispatch({ type: LOADING_END })
        returnedText(result)
      })
      .catch(error => {

        dispatch({ type: LOADING_END });
        console.log('error', error)
      });
  }
}

export function saveFormData(accessToken, templateId, requestBody, navigate, fields) {
  console.log(templateId, requestBody, navigate, fields, 'test')
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/data/`,
      accessToken,
      REQUEST_METHOD.POST,
      (successResponse) => {
        console.log("responceapi", successResponse)
        dispatch(setNotification(true, 'Data Saved Successfully', 'success'))
        dispatch({ type: LOADING_END })
        navigate ? navigate(`/forms/${templateId}/view_data`) : window.location.reload();
      },
      (failureResponse) => {
        console.log("fail", failureResponse)
        dispatch(setNotification(true, failureResponse, 'danger'))
        dispatch({ type: LOADING_END })
      },
      requestBody)
  }
}

export function fetchData(accessToken, id) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/data/${id}/`,
      accessToken, REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch({ type: FETCH_DATA, payload: successResponse })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}

export function updateFormData(accessToken, templateId, requestBody, id, navigate) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/data/${id}/`,
      accessToken,
      REQUEST_METHOD.PUT,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        navigate(`/forms/${templateId}/DataDetails/${id}/`)
        dispatch(setNotification(true, 'Data Updated Successfully', 'success'))
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }, requestBody)
  }
}
//update template table label
export function updateWorkflowFormData(accessToken, requestBody, id) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/data/${id}/update`,
      accessToken,
      REQUEST_METHOD.POST,
      (successResponse) => {
        dispatch({ type: LOADING_END })
       
        dispatch(setNotification(true, 'Data Updated Successfully', 'success'))
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse, 'danger'))
        dispatch({ type: LOADING_END })
      }, requestBody)
  }
}

export function deleteFormData(accessToken, dataId, formId) {
  return function (dispatch) {
    dispatch({ type: LOADING_START });
    fetchApi(`/forms/data/${dataId}/delete/`,
      accessToken,
      REQUEST_METHOD.DELETE,
      (successResponse) => {
        dispatch(setNotification(true, 'Deleted Successfully', 'danger'));
        dispatch(fetchFormData(accessToken, formId));
        dispatch({ type: LOADING_END })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse, 'danger'))
        dispatch({ type: LOADING_END })
      }, null, RESPONSE_TYPE.NULL
    )
  }
}

export function updateSharedData(accessToken, requestBody, Id, templateId) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/share/${Id}/`,
      accessToken,
      REQUEST_METHOD.PATCH,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch(setNotification(true, 'Data Updated Successfully', 'success'))
        dispatch(fetchSharedData(accessToken, templateId))
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      },
      requestBody)
  }
}

export function fileUpload(accessToken, uri, returnedData) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  var formdata = new FormData();
  formdata.append("file", uri);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetch(`${BASE_URL}/fileupload/files/`, requestOptions)
      .then(response => response.json())
      .then(result => {
        returnedData(result);
        dispatch({ type: LOADING_END });
      })
      .catch(error => {
        dispatch({ type: LOADING_END });
        console.log('error', error)
      });
  }
}

export function fileUpload_without_token(token, uri, returnedData) {
  var myHeaders = new Headers();
  var formdata = new FormData();
  formdata.append("file", uri);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetch(`${BASE_URL}/fileupload/files/${token}/post/`, requestOptions)
      .then(response => response.json())
      .then(result => {
        dispatch({ type: LOADING_END })
        returnedData(result)
      })
      .catch(error => {
        dispatch({ type: LOADING_END });
        console.log('fileUpload_without_token_error', error);
      });
  }
}

export function fetchPublicSharedForm(token) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/feature/share/${token}/form/`,
      null,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch({ type: FETCH_SHARED_FORM_FIELDS, payload: successResponse })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}


export function fetchPublicCustomSharedForm(form_id, token, returnedData) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/common/custom-form/${form_id}/${token}`,
      null,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        returnedData(successResponse)
      },
      (failureResponse) => {
        console.log("failureResponse", failureResponse)
        alert(failureResponse.statusText)
        dispatch({ type: LOADING_END })
      })
  }
}

export function fetchPublicSharedFormData(token, pageNumber = 1) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/feature/share/${token}/view/?page=${pageNumber}`,
      null,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch({ type: FETCH_SHARED_FORM_DATA, payload: successResponse })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}

export function savePublicSharedFormData(token, requestBody, navigate) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/feature/share/${token}/store/`,
      null,
      REQUEST_METHOD.POST,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        navigate(`/thankyou`)
        dispatch(setNotification(true, 'Form submitted successfully!', 'success'));
      },
      (failureResponse) => {
        console.log("I am here failureResponse", failureResponse)
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      },
      requestBody)
  }
}

export function fetchDownloadData(accessToken, templateId, templateLabel) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(
      `/forms/template/${templateId}/data/download/`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: LOADING_END });
        let blobResponse = URL.createObjectURL(successResponse);
        const downloadLink = document.createElement("a");
        downloadLink.download = `${templateLabel}.csv`;
        downloadLink.href = blobResponse;
        document.body.appendChild(downloadLink);
        downloadLink.click();
      },
      (failureResponse) => {
        this.props.showNotification(true, failureResponse.statusText, 'danger')
      }, null, RESPONSE_TYPE.BLOB
    )
  }
}

export function fetchFormEntries(accessToken, Id) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/template/${Id}/data/`,
      accessToken, REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch({ type: FETCH_FORM_ENTRY_DATA, payload: successResponse })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}

export function createComment(accessToken, requestBody) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/formdata/comment/`,
      accessToken,
      REQUEST_METHOD.POST,
      (successResponse) => {
        dispatch(setNotification(true, 'Comment created Successfully', 'success'))
        dispatch(fetchComments(accessToken, requestBody.formdata));
        dispatch({ type: LOADING_END })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      },
      requestBody)
  }
}

export function fetchComments(accessToken, dataId, pageNumber = 1) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/formdata/${dataId}/comment/?page=${pageNumber}`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: FETCH_COMMENTS, payload: successResponse })
        dispatch({ type: LOADING_END })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }
    )
  }
}

export function deleteComment(accessToken, commentId, dataId) {
  return function (dispatch) {
    dispatch({ type: LOADING_START });
    fetchApi(`/forms/formdata/comment/${commentId}/`,
      accessToken,
      REQUEST_METHOD.DELETE,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch(fetchComments(accessToken, dataId));
        dispatch(setNotification(true, 'Deleted Successfully', 'danger'));
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }, null, RESPONSE_TYPE.NULL
    )
  }
}

export function fetchFormMembers(accessToken, formId, pageNumber = 1) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/${formId}/users/`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: FETCH_FORM_MEMBERS, payload: successResponse })
        dispatch({ type: LOADING_END })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }
    )
  }
}

export function addFormMember(accessToken, requestBody, formId) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/common/formdatapermissions/`,
      accessToken,
      REQUEST_METHOD.POST,
      (successResponse) => {
        dispatch(setNotification(true, "Member Added successfully", "success"));
        dispatch(fetchFormMembers(accessToken, formId));
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      },
      requestBody)
  }
}

export function fetchMembers(accessToken, pageNumber = 2) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/common/formdatapermissions/`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: FETCH_MEMBERS, payload: successResponse })
        dispatch({ type: LOADING_END })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }
    )
  }
}

export function updateFormMember(accessToken, requestBody) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/common/formdatapermissions/${requestBody.ID}/`,
      accessToken,
      REQUEST_METHOD.PUT,
      (successResponse) => {
        dispatch(setNotification(true, 'Member Updated Successfully', 'success'))
        dispatch({ type: FETCH_MEMBERS, payload: successResponse })
        dispatch({ type: LOADING_END })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      },
      requestBody)
  }
}

export function deleteFormMember(accessToken, memberId, formId) {
  return function (dispatch) {
    dispatch({ type: LOADING_START });
    fetchApi(`/common/formdatapermissions/${memberId}/delete/`,
      accessToken,
      REQUEST_METHOD.DELETE,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch(fetchFormMembers(accessToken, formId));
        dispatch(setNotification(true, 'Removed Successfully', 'success'));
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }, null, RESPONSE_TYPE.NULL
    )
  }
}

export function AddWorkflow(accessToken, requestBody) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/workflow/template/`,
      accessToken,
      REQUEST_METHOD.POST,
      (successResponse) => {
        dispatch(setNotification(true, 'Workflow Added Successfully', 'success'))
        dispatch(fetchForms(successResponse, requestBody.template))
        dispatch(fetchWorkFlowTemplate(accessToken))
        dispatch({ type: LOADING_END })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      },
      requestBody)
  }
}

export function fetchWorkFlowTemplate(accessToken) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/workflow/template/`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: FETCH_WORKFLOW_TEMPLATE, payload: successResponse })
        dispatch({ type: LOADING_END })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }
    )
  }
}

export function removeWorkFlowTemplate(accessToken, id) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/workflow/template/${id}/`,
      accessToken,
      REQUEST_METHOD.DELETE,
      (successResponse) => {
        dispatch(setNotification(true, 'Workflow Remove Successfully', 'warning'))
        dispatch(fetchWorkflowList(accessToken))
        dispatch(fetchWorkFlowTemplate(accessToken))
        dispatch({ type: LOADING_END })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }, null, RESPONSE_TYPE.NULL
    )
  }
}

export function checkUnique(accessToken, templateId, fieldId, value, returnedData) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/formdata/${templateId}/${fieldId}/${value}/`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        returnedData(successResponse)
        dispatch({ type: FETCH_UNIQUE_STATUS, payload: successResponse })
        dispatch({ type: LOADING_END })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }
    )
  }
}

export function checkUserLevel(accessToken, templateId) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/common/template/${templateId}/`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: FETCH_USER_LEVEL, payload: successResponse })
        dispatch({ type: LOADING_END })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }
    )
  }
}

export function getWorkflowAction(accessToken, Id) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/data/${Id}/action/`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: WORKFLOW_ACTION, payload: successResponse })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }
    )
  }
}

export function getWorkflowActionLog(accessToken, Id, pageNumber = 1) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/workflow/data/${Id}/action_log/?page=${pageNumber}`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch(getWorkflowAction(accessToken, Id))
        dispatch({ type: LOADING_END })
        dispatch({ type: WORKFLOW_ACTION_LOG, payload: successResponse })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }
    )
  }
}

export function workflowActionComment(accessToken, requestBody) {
  return function (dispatch) {
    dispatch({ type: LOADING_START });
    fetchApi(`/workflow/action/`,
      accessToken,
      REQUEST_METHOD.POST,
      (successResponse) => {
        dispatch(getWorkflowActionLog(accessToken, requestBody.form_data))
        dispatch(setNotification(true, 'Workflow Comment Added Successfully', 'success'))
        dispatch({ type: LOADING_END })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      },
      requestBody)
  }
}

export function getHiddenFieldDefaultData(accessToken, Id, pageNumber = 1) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/workflow/data/${Id}/action_log/?page=${pageNumber}`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch(getWorkflowAction(accessToken, Id))
        dispatch({ type: LOADING_END })
        dispatch({ type: WORKFLOW_ACTION_LOG, payload: successResponse })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }
    )
  }
}

export function fetchFieldValue(accessToken, templateId, fieldId) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/common/defaultvalue/${templateId}/${fieldId}/`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: HIDDEN_FIELD_VALUE, payload: successResponse })
        dispatch({ type: LOADING_END })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }
    )
  }
}
export function searchTemplateData(accessToken, templateId, fieldId, value) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/template/${templateId}/searchdata/?field_id=${fieldId}&value=${value}`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: FETCH_FORM_DATA, payload: { "data": successResponse } })
        dispatch({ type: LOADING_END })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }
    )
  }
}
export function advanceSearch(accessToken, templateId, value) {
  return function (dispatch) {
    dispatch({ type: LOADING_START })
    fetchApi(`/forms/template/${templateId}/advancedsearch/?value=${value}`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: FETCH_FORM_DATA, payload: { "data": successResponse } })
        dispatch({ type: LOADING_END })
      },
      (failureResponse) => {
        dispatch(setNotification(true, failureResponse.statusText, 'danger'))
        dispatch({ type: LOADING_END })
      }
    )
  }
}

//all template and the forms 
export function fetchAllMenuList(accessToken) {
  return function (dispatch) {
    dispatch({ type: LOADING_START });
    fetchApi(`/forms/menulistforuser/`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch({ type: FETCH_MENU_LIST, payload: successResponse })
      },
      (failureResponse) => {
        console.log(failureResponse)
        dispatch(setNotification(true, failureResponse, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}


export function gettemplateData(accessToken,id) {
  return function (dispatch) {
    dispatch({ type: LOADING_START });
    fetchApi(`/forms/custumlink/${id}/data/
`,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: LOADING_END })
        dispatch({ type: FETCH_TEMPLATE_DATA, payload: successResponse })
      },
      (failureResponse) => {
        console.log(failureResponse)
        dispatch(setNotification(true, failureResponse, 'danger'))
        dispatch({ type: LOADING_END })
      })
  }
}

export function gettemplateSearchData(accessToken, id, query,fun) {
  return function (dispatch) {
    dispatch({ type: LOADING_START });

    // Construct the URL with the query parameter
    const url = `/forms/custumlink/${id}/data/?query=${encodeURIComponent(query)}`;

    fetchApi(
      url,
      accessToken,
      REQUEST_METHOD.GET,
      (successResponse) => {
        dispatch({ type: LOADING_END });
        fun(successResponse)
        // dispatch({ type: FETCH_TEMPLATE_DATA, payload: successResponse });
      },
      (failureResponse) => {
        console.log(failureResponse);
        dispatch(setNotification(true, failureResponse, 'danger'));
        dispatch({ type: LOADING_END });
      }
    );
  };
}
